import React from 'react';
import {MainLayout} from './components/MainLayout';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {LoginPage} from './pages/Login/LoginPage';
import moment from 'moment';
import 'moment/locale/it';
import {Grommet} from 'grommet';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reduxStore } from './store/reducers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

moment.locale('it');

const theme = {
    global: {
        colors: {
            donatoni_1: '#005AA5',   // blu donatoni
            donatoni_2: '#BFBFBF',  // grigio donatoni (dal logo)
            donatoni_black: '#1F1F1F', 
            donatoni_white: '#F5F5F5', 
            news: '#2A1A1F', 
        },
        font: {
            family: 'Rajdhani',
            size: '14px',
            height: '20px',
        },
    },
};

const store = createStore(reduxStore);

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <Grommet theme={theme}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={LoginPage} />
                        <Route path="/app" component={MainLayout} />
                        <Route component={LoginPage} />
                    </Switch>
                </BrowserRouter>
                <ToastContainer 
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover
                />
            </Grommet>
        </Provider>
    );
};

export default App;
