import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    link: {
        flexGrow: 1, 
        textAlign: 'right',
        fontSize: '2em'
    },
    icon: {
        fontSize: '2em'
    }
}

export default styles;
