import Photo from './attachment/photo';
import Software from './product/software';
import Optional from './optional/optional';
import Machine from './product/machine';

export enum PoiType {
    optional,
    software,
    free
}

export default interface Poi {
    id: number;
    type: PoiType;
    title: string;
    description: string;
    refElementName: string;
    software: Software;
    optional: Optional;
    photos: Photo[];
    videos: string;
    machines: Machine[];
    parent_id: number;
}
