import React, { useState } from 'react'
import YouTube from 'react-youtube'
import './style.scss'
import { Box, Heading } from 'grommet'

interface Props {
    key?: number;
    videoId: string;
    hideTitle?: boolean;
}

const VideoShow = (props: Props) => {
    const [title, setTitle] = useState<string>('')
    const handleReady = (event:any) => setTitle(event.target.getVideoData().title)

    return (
        <Box direction="column" align="stretch">
            <YouTube
                containerClassName="video-responsive"
                videoId={props.videoId}
                onReady={handleReady}
                opts={{
                    playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0,
                        rel: 0,
                        modestbranding: 1,
                        iv_load_policy: 3,
                        disablekb: 1,
                        color: 'white'
                    },
                }}
            />
            <Box pad="small" width="100%">
                {!props.hideTitle && <Heading level="2">{title}</Heading>}
            </Box>
        </Box>
    )
}

export default VideoShow
