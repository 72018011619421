export default class SearchManager {
    
    private static searchName(entity: any, textToFind: string){
        return entity.name.toLowerCase().includes(textToFind.toLowerCase())
    }

    private static searchTags(entity: any, textToFind: string){
        if(entity.tags){
            for (const tag of JSON.parse(entity.tags)) {
                if(tag.toLowerCase().includes(textToFind.toLowerCase())){
                    return true
                }
            }
        }

        return false
    }

    public static startSearch(entities: Record<string, any>[] | null, textToFind: string): Record<string, any>[] {
        if(entities && textToFind.length > 0){
            return entities.filter(entity => {
                return this.searchName(entity,textToFind) || this.searchTags(entity, textToFind)
            })
        } else {
            return [];
        }
    }

    /**
     * Verifies that 2 entities has at lease 1 common tag
     * @param sourceEntity 
     * @param targetEntity 
     */
    public static searchSameTag(sourceEntity: any,targetEntity: any){
        if (sourceEntity && targetEntity && sourceEntity.id !== (targetEntity.parent_id ?? targetEntity.id) && sourceEntity.tags && (targetEntity.parent_id ? targetEntity.parent.tags : targetEntity.tags)){
            for (const sourceTag of JSON.parse(sourceEntity.tags)) {
                for (const targetTag of JSON.parse(targetEntity.parent_id ? targetEntity.parent.tags : targetEntity.tags)) {
                    if(sourceTag.toLowerCase() === targetTag.toLowerCase()){
                        return true
                    }
                }
            }
        }

        return false
    }
}