import React, { ReactNode } from 'react'
import { ResponsiveContext, Grid as GrommetGrid } from 'grommet';

interface Props {
    rows: Record<string, string[]>;
    columns: Record<string, string[]>;
    children: ReactNode;
}

const Grid = (props: Props) => {
    return (
        <ResponsiveContext.Consumer>
            {size => {
                
                let columnsVal;
                if (props.columns) {
                    if (props.columns[size]) {
                        columnsVal = props.columns[size];
                    } else {
                        const keys = Object.keys(props.columns)
                        columnsVal = props.columns[keys[keys.length-1]];
                    }
                }

                let rowsVal;
                if (props.rows) {
                    if (props.rows[size]) {
                        rowsVal = props.rows[size];
                    } else {
                        const keys = Object.keys(props.rows)
                        rowsVal = props.rows[keys[keys.length - 1]];
                    }
                }

                return (
                    <div>
                        <GrommetGrid
                            rows={!rowsVal ? size : rowsVal}
                            columns={!columnsVal ? size : columnsVal}
                            gap="medium" 
                            pad="medium"
                            fill>
                            {props.children}
                        </GrommetGrid>
                    </div>
                );
            }}
        </ResponsiveContext.Consumer>
    );
}

export default Grid
