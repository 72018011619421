import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Optional from '../models/optional/optional';

export default class OptionalsController {
    public static async getAll(): Promise<Optional[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.OPTIONALS), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni degli optional')
        }
    }

    public static async create(optional: Optional): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.OPTIONALS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione dell\'optional')
        }
    }

    public static async update(optional: Optional): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.OPTIONALS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento dell\'optional')
        }
    }

    public static async get(id: number): Promise<Optional> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.OPTIONALS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni dell\'optional')
        }
    }

    public static async delete(id: number): Promise<Optional> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.OPTIONALS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione dell\'optional')
        }
    }
}