import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import Machine from '../../models/product/machine';
import Reference from '../../models/reference';
import LanguageManager from '../../backend/LanguageManager';

const MachineReferencesPage: React.FC = () => {
    const [machine, setMachine] = useState<Machine>()
    const [machineReferences, setMachineReferences] = useState<Reference[]>([])
    const {references, entity} = useSelector((state:State) => state)

    useEffect(() => {
        const getMachineBenches = async () => {
            if(entity && references){
                const referencesIds: number[] = entity.references ? entity.references.map((reference:Reference) => reference.id) : []
                setMachineReferences(references.filter((reference:Reference) => referencesIds.includes(reference.parent_id ?? reference.id)))
                setMachine(machine)
            }
        }

        getMachineBenches()
        // eslint-disable-next-line
    }, [references])

    return <EntityPage pageTitle={`${LanguageManager.get('references_of_machine', true)} ${machine ? machine.name : ''}`} entityName='reference' entities={machineReferences} />
};

export default MachineReferencesPage;
