import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Software from '../models/product/software';

export default class SoftwaresController {
    public static async getAll(): Promise<Software[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.SOFTWARES), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni dei software')
        }
    }

    public static async create(optional: Software): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.SOFTWARES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione del software')
        }
    }

    public static async update(optional: Software): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.SOFTWARES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento del software')
        }
    }

    public static async get(id: number): Promise<Software> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.SOFTWARES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni del software')
        }
    }

    public static async delete(id: number): Promise<Software> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.SOFTWARES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione del software')
        }
    }
}