import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        textAlign: 'center',
        lineHeight: 1,
        alignSelf: 'center',
        fontSize: '5em',
        opacity: '50%',
        paddingTop: 15,
    },
    icon: {
        fontSize: '2em'
    }
}

export default styles;