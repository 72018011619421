import React, {  useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { State } from '../store/state';
import Attachment from '../models/attachment/attachment';
import AttachmentsController from '../backend/AttachmentsController';
import Gallery from '../components/gallery';
import DownloadButton from '../components/downloadButton';

const DocumentsPage = () => {
    const entity = useSelector((state: State) => state.entity)
    const [documents, setDocuments] = useState<Attachment[]>([])

    useEffect(() => {
        const getPhotos = async () => {
            if(entity && entity.documents){
                try {
                    const atts: Attachment[] = [];
                    for (const att of entity.documents) {
                        if(att.id){
                            atts.push(await AttachmentsController.get(att.id))
                        }
                    }

                    setDocuments(atts)
                } catch (error) {
                    alert(error.message)
                }
            }
        }

        getPhotos()
    }, [entity])

    const handleGetContent = (document: Attachment) => <DownloadButton attachment={document} />

    const handleGetTitle = (document: Attachment) => document.name

    return <Gallery items={documents} getContent={handleGetContent} getTitle={handleGetTitle} elementBackground="donatoni_1" /> 
}

export default DocumentsPage
