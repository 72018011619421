import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Working from '../models/working';

export default class WorkingsController {
    public static async getAll(): Promise<Working[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.WORKINGS), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni delle lavorazioni')
        }
    }

    public static async create(optional: Working): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.WORKINGS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione della lavorazione')
        }
    }

    public static async update(optional: Working): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.WORKINGS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento della lavorazione')
        }
    }

    public static async get(id: number): Promise<Working> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.WORKINGS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni della lavorazione')
        }
    }

    public static async delete(id: number): Promise<Working> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.WORKINGS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione della lavorazione')
        }
    }

    public static async bindOptional(workingId: number, machineId: number): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.WORKINGS, workingId) + '/machine', { machineId: machineId }, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'associazione della lavorazione alla macchina')
        }
    }

    public static async unbindOptional(workingId: number, machineId: number): Promise<void> {
        try {
            await axios.delete(ApiHelper.getEndpoint(RouteName.WORKINGS, workingId) + '/machine/' + machineId, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione dell\'associazione tra lavorazione e la macchina')
        }
    }
}