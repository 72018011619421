import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from '../../store/state'
import SearchManager from '../../backend/searchManager'
import { List, Box, Heading, Text } from 'grommet'
import { FaArrowRight } from 'react-icons/fa'
import styles from './styles'
import EntityIcon from '../../components/entityIcon'
import { EntityType } from '../../backend/entityType'
import { useHistory } from 'react-router'
import ContextPage from '../../components/contextPage'
import LanguageManager from '../../backend/LanguageManager'

export const Related = () => {
    const {entity, machines, softwares, optionals, benches, modules, workings, references, services} = useSelector((state: State) => state)
    const [relatedEntities, setRelatedEntities] = useState<any[]>([]);
    const history = useHistory();

    useEffect(() => {
        const entityFamilies = [
            {entities: machines, type: EntityType.MACHINE}, 
            {entities: softwares, type: EntityType.SOFTWARE}, 
            {entities: optionals, type: EntityType.OPTIONAL}, 
            {entities: benches, type: EntityType.BENCH}, 
            {entities: modules, type: EntityType.SOFTWARE_MODULE}, 
            {entities: workings, type: EntityType.WORKING}, 
            {entities: references, type: EntityType.REFERENCE}, 
            {entities: services, type: EntityType.SERVICE}
        ];
        const entities: any[] = [];

        for (const entityFamily of entityFamilies) {  
            if(entityFamily.entities && entityFamily.entities.length > 0){
                for (const targetEntity of entityFamily.entities) {   
                    if(SearchManager.searchSameTag(entity, targetEntity)){
                        entities.push({entity: targetEntity, type: entityFamily.type});
                    }
                } 
            }
        }

        setRelatedEntities(entities);
    }, [entity, machines, softwares, optionals, benches, modules, workings, references,services])

    return (
        <ContextPage entity={entity}>
            <Box pad="small">
                <Heading>{LanguageManager.get('related', true)}</Heading>
                <List data={relatedEntities} onClickItem={(event: any)=>history.push(`/app/${event.item.type}/${event.item.entity.id}`)}>
                    {(datum:any, index:number) => (
                        <Box
                            key={index}
                            direction="row-responsive"
                            gap="large"
                            pad="xsmall"
                            align="center"
                        >
                            <EntityIcon style={styles.icon} type={datum.type} />
                            <Text weight="bold">{datum.entity.name}</Text>
                            <div style={styles.link}>
                                <FaArrowRight />
                            </div>
                        </Box>
                    )}

                </List>
            </Box>
        </ContextPage>
    )
}
