import React, { useEffect } from 'react';
import Playground from '../../components/Playground';
import {useState} from 'react';
import AttachmentsController from '../../backend/AttachmentsController';
import { useSelector } from 'react-redux';
import { State } from '../../store/state';
import Poi, { PoiType } from '../../models/poi';
import { useParams } from 'react-router';
import Machine from '../../models/product/machine';
import Loading from '../../components/loading';
import { Layer, Box, Button, Heading } from 'grommet';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import { IoMdListBox } from 'react-icons/io';
import styles from './styles';
import { MdSettingsInputComponent, MdDesktopMac } from 'react-icons/md';
import Iframe from 'react-iframe';
import ModalLayer from '../../components/modalLayer';

const Machine3DPage: React.FC = () => {
    const {machines, pois} = useSelector((state: State) => state);
    const [modelUrl, setModelUrl] = useState<string>('')
    const [poiUrl, setPoiUrl] = useState<string>('')
    const [poi, setPoi] = useState<Poi>()
    const [navigatePoi, setNavigatePoi] = useState<boolean>(false)
    const [showPoiPreview, setShowPoiPreview] = useState<boolean>(false)
    const {id} = useParams()

    useEffect(() => {
        const currentMachine : any = machines && machines.find((machine: Machine) => machine.id === +id);
        
        if (currentMachine) {
            const modelId = currentMachine.parent_id ? currentMachine.parent.model_id : currentMachine.model_id!;
            setModelUrl(AttachmentsController.getContentLink(modelId))
        }
        // eslint-disable-next-line
    }, [machines])


    const handlePoiClick = (scene: any, poiId: number | null, mesh: any) => {
        if(pois && poiId){
            const poi: Poi | undefined = pois.find(poi => poi.id === +poiId)
            if(poi){
                setPoi(poi)
                setShowPoiPreview(true);
            }
        } else {
            setShowPoiPreview(false);
        }
    }

    const poiNavigation = () => {
        if(pois && poi){
            if(poi){
                let url = "";

                switch(+poi.type){
                case PoiType.free:
                    url = `/app/poi/${poi.id}`;
                    break;
                case PoiType.optional: 
                    url = '/app/optional/'+poi.optional.id;
                    break;
                case PoiType.software:
                    url = '/app/software/'+poi.software.id;
                    break;
                }
                setPoiUrl(`${url}?poiNavigation=1&firstPage=1`);
                enablePoiNavigation();
            } else {
                console.log('poi not found')
            }
        } else {
            console.log('no pois defined!')
        }
    }
    
    const enablePoiNavigation = () => {
        setShowPoiPreview(false)
        setNavigatePoi(true)
    }
    
    const disablePoiNavigation = () => {
        setNavigatePoi(false)
    }

    return modelUrl && pois ? (
        <div>
            <Playground 
                machineId={+id} 
                modelUrl={modelUrl} 
                pois={pois} 
                onPoiClick={handlePoiClick} />
            {poi && showPoiPreview && (
                <Layer position="top" modal={false} margin={{top: '60'}} responsive={false}>
                    <Box direction="row" align="center" justify="center" pad="small">
                        {+poi.type === PoiType.free && <IoMdListBox style={styles.selectIcon} />}
                        {+poi.type === PoiType.optional && <MdSettingsInputComponent style={styles.selectIcon} />}
                        {+poi.type === PoiType.software && <MdDesktopMac style={styles.selectIcon} />}
                        <Heading level="2">{poi.title}</Heading>
                        <Button onClick={poiNavigation}>
                            <FaCheckCircle style={styles.selectIcon} />
                        </Button>
                        <Button onClick={ () => setPoi(undefined)}>
                            <FaTimesCircle style={styles.selectIcon} />
                        </Button>
                    </Box>
                </Layer>
            )}
            {poi && navigatePoi && <ModalLayer title={poi.title} onClose={disablePoiNavigation} full>
                <Iframe url={poiUrl}
                    width="100%"
                    height={window.innerHeight.toString()}
                    id="myId"
                    overflow="hidden"
                    className="myClassname"
                    position="relative"/>
            </ModalLayer>}
        </div>
    ) : (
        <Loading title="Caricamento 3D" />
    )
};

export default Machine3DPage;
