import React, { useEffect } from 'react';
import { useParams} from 'react-router';
import Optional from '../../models/optional/optional';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import OptionalsController from '../../backend/OptionalsController';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';

const OptionalViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {optionals} = useSelector((state: State) => state)

    useEffect(() => {
        const getOptional = async () => {
            const currentOptional = optionals && optionals.find((optional: Optional) => optional.id === +id);

            if(ApiHelper.language !== Language.it && currentOptional && currentOptional.parent_id){
                try {
                    const parentMachine = await OptionalsController.get(currentOptional.parent_id);
                    parentMachine.name = currentOptional.name;
                    parentMachine.description = currentOptional.description;

                    dispatch({type: SET_ENTITY, entity: parentMachine})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentOptional});
            }
        }

        getOptional()
        // eslint-disable-next-line
    }, [optionals])

    return <EntityView />
};

export default OptionalViewPage;
