import React from 'react';
import {Header, Box, Text} from 'grommet';

interface Props {
    suggestionName: string;
    typeName: string;
}

const SearchSuggestion: React.FC<Props> = props => {
    const {suggestionName, typeName} = props;

    return (
        <Header margin="small">
            <Text>{suggestionName}</Text>
            <Box background="donatoni_1" round pad="xsmall">
                <Text>{typeName.toLowerCase()}</Text>
            </Box>
        </Header>
    );
};

export default SearchSuggestion;
