import User from '../models/user';
import axios, { AxiosResponse } from 'axios';
import ApiHelper from './apiHelper';
export default class LoginController {

    public static async verify(): Promise<User | undefined> {
        try {
            return (await axios.post(ApiHelper.verifyEndpoint, null, {
                headers: ApiHelper.headers,
            })).data;
        } catch (error) {
            if(!error.response) {
                throw error;
            } else if(error.response.status === 401){
                throw new Error('L\'utente non è autorizzato')
            } 
        }
    }

    public static async login(username: string, password: string) {
        try {
            const response: AxiosResponse = await axios.post(ApiHelper.loginEndpoint, JSON.stringify({ username: username, password: password }), {
                headers: ApiHelper.headers,
            });
            ApiHelper.setLocalToken(response.data.token);
        } catch (error) {
            if(error.response.status === 401){
                throw new Error('Utente non autorizzato ad accedere')
            } else {
                throw new Error('Verificare le credenziali inserite')
            }
        }
    }

    public static logout() {
        ApiHelper.clearToken();
        window.location.reload();
    }
}