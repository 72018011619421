import it from './lang/it'
import en from './lang/en'
import de from './lang/de'
import fr from './lang/fr'
import ApiHelper from './apiHelper';
import { Language } from '../models/language';

export default class LanguageManager {
    
    private static getKeys(): Record<string, string>{
        switch(ApiHelper.language){
        case Language.it:
            return it;
        default:
        case Language.en:
            return en;
        case Language.de:
            return de;
        case Language.fr:
            return fr;
        }
    }

    public static get(key: string, capitalize?: boolean): string{
        const value = this.getKeys()[key]

        if(value){
            return capitalize ? value.replace(/^\w/, c => c.toUpperCase()) : value;
        } else {
            return ''
        }
    }
}