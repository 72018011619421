import React, { useEffect, useState } from 'react'
import { Box, Button, Heading, Image } from 'grommet'
import {VectorMap} from 'react-jvectormap';
import ModalLayer from '../../components/modalLayer';
import WorldzoneController from '../../backend/worldzoneController';
import { toast } from 'react-toastify';
import Countries, { Country } from 'world-countries';
import regions from '../../utils/regionCodes';
import Grid from '../../components/grid';
import { FaGlobeEurope, FaTimes } from 'react-icons/fa';

const rows = {
    small: ["auto", "flex"],
};

const columns = {
    small: ["auto"],
    medium: ["auto", "flex"],
};

const WorldPage = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [selectedRegionCode, setSelectedRegionCode] = useState<string | null>();
    const [worldzoneCounts, setWorldzoneCounts] = useState<any>([]);
    const [currentRegions, setCurrentRegions] = useState<any>([])
    const [machines, setMachines] = useState<any[]>([])

    const getAllCountries = async () => {
        try {
            setWorldzoneCounts(await WorldzoneController.getAllCounts()); 
        } catch (error) {
            toast.error(error.message)
        }
    } 

    const getcurrentRegions = async (countryCode: string) => {
        try {
            setCurrentRegions(await WorldzoneController.getRegions(countryCode));
        } catch (error) {
            toast.error(error.message);
        }
    }

    const getMachines = async (countryCode: string) => {
        try {
            setMachines(await WorldzoneController.getMachines(countryCode));
        } catch (error) {
            toast.error(error.message);
        }
    }

    const showCountry = async (countryCode: string) => {
        setSelectedRegionCode(null);
        setSelectedCountry(Countries.find(country => country.cca2 === countryCode));
        setShowDetails(true);
        await getcurrentRegions(countryCode);
        await getMachines(countryCode);
    }

    const handleRegionClick = async (regionCode: string) => {
        if(selectedCountry){
            try {
                setSelectedRegionCode(regionCode);
                setCurrentRegions(await WorldzoneController.getRegions(selectedCountry?.cca2));
                setMachines(await WorldzoneController.getMachinesByRegion(regionCode));
            } catch (error) {
                toast.warn(error.message)
            }
        }
    }

    const handleMachineClick = async (machineName: string)=>{
        if(selectedCountry?.cca2 === 'IT'){
            try {
                setCurrentRegions(await WorldzoneController.getRegionsByMachine(machineName));
            } catch (error) {
                toast.error(error.message);
            }
        }
    }

    const getRegionName = (regionCode: string) => {
        const allRegions: any = regions;
        const regionName = allRegions[regionCode]

        return regionName ? regionName.replace(/^\w/, (c:string) => c.toUpperCase()) : '';
    }

    useEffect(() => {
        getAllCountries();
    },[])

    return (
        <Box >
            <VectorMap map='world_mill'
                backgroundColor="#F5F5F5"
                containerStyle={{
                    width: '100%',
                    height: '100vh',
                    paddingLeft: '10px'
                }}
                regionStyle={{
                    initial: {
                        fill: 'white',
                        "fill-opacity": 1,
                        stroke: '#7A7A7A',
                        "stroke-width": 0.1,
                        "stroke-opacity": 1
                    },
                    hover: {
                        "fill-opacity": 0.8,
                        cursor: 'pointer'
                    },
                    selected: {
                        fill: 'yellow'
                    },
                    selectedHover: {
                    }
                }}
                containerClassName="map"
                series={{
                    regions: [{
                        values: worldzoneCounts,
                        scale: ['#C8EEFF', '#00149A'],
                        normalizeFunction: 'polynomial'
                    }]
                }}
                onRegionClick={async (e: any, code: string, other: any)=> {
                    Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el:any) => { el.style.display = "none"; });
                    await showCountry(code);
                }}
                onRegionOver={(e: any, code: string)=> {
                    Array.from(document.getElementsByClassName("jvectormap-tip")).forEach((el:any) => { el.style.display = "none"; });
                }}
            />
            {showDetails && (
                <ModalLayer closeOnCapture={false} title={selectedCountry?.translations.ita.common || ''} onClose={()=>setShowDetails(false)}>
                    <Box align="center">
                        <Image width="80" style={{ border: '1px solid gray'}} src={`https://flagcdn.com/w80/${selectedCountry?.cca2.toLowerCase()}.jpg`} />
                        <Heading level="3" margin="medium">{selectedCountry && worldzoneCounts[selectedCountry?.cca2] > 0 ? 
                            `${selectedCountry && worldzoneCounts[selectedCountry?.cca2]} macchine` :
                            `In questo paese non sono ancora presenti macchine Donatoni`
                        }</Heading>
                        <Grid columns={columns} rows={rows}>
                            {currentRegions && Object.keys(currentRegions).length > 0 && (
                                <Box>
                                    <Heading level="4">Regioni</Heading>
                                    <Box direction="row" align="center">
                                        <Button margin="small" secondary color="donatoni_1" icon={<FaGlobeEurope />} onClick={()=>selectedCountry && getcurrentRegions(selectedCountry?.cca2)} />
                                        <Heading level="6">Visualizza tutte</Heading>
                                    </Box>
                                    {Object.keys(currentRegions).map((regionKey:string, index:number) => (
                                        <Button key={index} secondary color="donatoni_1" margin="small" onClick={()=>handleRegionClick(regionKey)} label={`${getRegionName(regionKey)}: ${currentRegions[regionKey]} macchine`}></Button>
                                    ))}
                                </Box>
                            )}
                            <Box>
                                {machines && <Heading level="4">Macchine</Heading>}
                                {currentRegions && Object.keys(currentRegions).length > 0 && selectedRegionCode && (
                                    <Box direction="row" align="center">
                                        <Button margin="small" secondary color="donatoni_1" icon={<FaTimes />} onClick={()=>selectedCountry && showCountry(selectedCountry?.cca2)} />
                                        <Heading level="6">{getRegionName(selectedRegionCode)}</Heading>
                                    </Box>
                                )}
                                <Box direction="row" wrap>
                                    {machines && Object.keys(machines).map((machineKey: any, index:number) => (
                                        <Button margin="small" key={index} primary color="donatoni_1" onClick={()=>handleMachineClick(machineKey)} label={`${machineKey} (${machines[machineKey]})`} />
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                </ModalLayer>
            )}
        </Box>
    )
}

export default WorldPage
