import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Poi from '../models/poi';

export default class PoisController {
    public static async getAll(): Promise<Poi[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.POIS), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni dei punti d\'interesse')
        }
    }

    public static async create(optional: Poi): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.POIS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione del punto d\'interesse')
        }
    }

    public static async update(optional: Poi): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.POIS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento del punto d\'interesse')
        }
    }

    public static async get(id: number): Promise<Poi> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.POIS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni del punto d\'interesse')
        }
    }

    public static async delete(id: number): Promise<Poi> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.POIS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione del punto d\'interesse')
        }
    }

    public static async bindWithMachine(poi: Poi, machineId: number, refElementName: string): Promise<Poi> {
        try {
            return (await axios.post(ApiHelper.getEndpoint(RouteName.POIS, poi.id) + '/machine/' + machineId, { refElementName: refElementName }, {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'associazione del punto d\'interesse alla macchina')
        }
    }

    public static async unbindFromMachine(poi: Poi, machineId: number): Promise<void> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.POIS, poi.id) + '/machine/' + machineId, {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante la rimozione dell\'associazione del punto d\'interesse alla macchina')
        }
    }
}