import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import Service from '../../models/service';
import ServiceController from '../../backend/serviceController';

const ServiceViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {services} = useSelector((state: State) => state)

    useEffect(() => {
        const getWorking = async () => {
            const currentService = services && services.find((service: Service) => service.id === +id);

            if(ApiHelper.language !== Language.it && currentService && currentService.parent_id){
                try {
                    const parentService = await ServiceController.get(currentService.parent_id);
                    parentService.name = currentService.name;
                    parentService.description = currentService.description;

                    dispatch({type: SET_ENTITY, entity: parentService})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentService});
            }
        }

        getWorking()
        // eslint-disable-next-line
    }, [services])

    return <EntityView />
};

export default ServiceViewPage;
