import React, { ReactNode } from 'react'
import { Layer, Header, Heading, Button, Box } from 'grommet'
import { FaTimes } from 'react-icons/fa'
import styles from './styles'

interface Props {
    title:string;
    children: ReactNode
    onClose?: ()=>void;
    closeOnCapture?: boolean;
    slide?: boolean;
    full?: boolean
}

const ModalLayer = (props: Props) => {
    return (
        <div>
            <Layer 
                onEsc={props.onClose}
                onClickOutside={props.onClose}
                onClickCapture={() => props.onClose && props.closeOnCapture && props.onClose()}
                animation={props.slide ? "slide" : "fadeIn"}
                full={props.full ? true : "horizontal"}
                position="top"
                responsive
                style={{overflow: 'auto', padding: '10px'}}
            >
                <Header style={styles.header}>
                    <Heading size="1.5em">{props.title}</Heading>
                    <Button style={styles.button} icon={<FaTimes />} onClick={props.onClose} />
                </Header>
                <Box responsive>
                    {props.children}
                </Box>
            </Layer>
        </div>
    )
}

export default ModalLayer
