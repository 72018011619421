import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import SoftwareModule from '../../models/optional/softwareModule';
import SoftwareModulesController from '../../backend/SoftwareModulesController';

const SoftwareModulesViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {modules} = useSelector((state: State) => state)

    useEffect(() => {
        const getMachine = async () => {
            const currentModule = modules && modules.find((module: SoftwareModule) => module.id === +id);

            if(ApiHelper.language !== Language.it && currentModule && currentModule.parent_id){
                try {
                    const parentModule = await SoftwareModulesController.get(currentModule.parent_id);
                    parentModule.name = currentModule.name;
                    parentModule.description = currentModule.description;

                    dispatch({type: SET_ENTITY, entity: parentModule})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentModule});
            }
        }

        getMachine()
        // eslint-disable-next-line
    }, [modules])

    return <EntityView />
};

export default SoftwareModulesViewPage;
