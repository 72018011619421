import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import Working from '../../models/working';
import WorkingsController from '../../backend/WorkingsController';

const WorkingViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {workings} = useSelector((state: State) => state)

    useEffect(() => {
        const getWorking = async () => {
            const currentWorking = workings && workings.find((workings: Working) => workings.id === +id);

            if(ApiHelper.language !== Language.it && currentWorking && currentWorking.parent_id){
                try {
                    const parentWorking = await WorkingsController.get(currentWorking.parent_id);
                    parentWorking.name = currentWorking.name;
                    parentWorking.description = currentWorking.description;

                    dispatch({type: SET_ENTITY, entity: parentWorking})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentWorking});
            }
        }

        getWorking()
        // eslint-disable-next-line
    }, [workings])

    return <EntityView />
};

export default WorkingViewPage;
