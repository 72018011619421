import ApiHelper, { RouteName } from "./apiHelper";
import axios from "axios";
import ExtraEntity from "../models/extraEntity";

export default class ExtraEntityController {
    public static async getAll(): Promise<ExtraEntity[]> {
        try {
            return (
                await axios.get(ApiHelper.getEndpoint(RouteName.EXTRA_ENTITY), {
                    headers: ApiHelper.headers
                })
            ).data;
        } catch (error) {
            console.log(error.message);
            throw new Error(
                "Errore durante il download delle informazioni extra"
            );
        }
    }
}
