import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    header: {
        padding: '.5em'
    },
    button: {
        fontSize: '2em'
    },
}

export default styles;