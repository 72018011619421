const regions = {
    NC: "Non classificate",
    "IT-65": "abruzzo",
    "IT-77": "basilicata",
    "IT-78": "calabria",
    "IT-72": "campania",
    "IT-45": "emilia romagna",
    "IT-62": "lazio",
    "IT-42": "liguria",
    "IT-25": "lombardia",
    "IT-57": "marche",
    "IT-67": "molise",
    "IT-21": "piemonte",
    "IT-75": "puglia",
    "IT-52": "toscana",
    "IT-55": "umbria",
    "IT-34": "veneto",
    "IT-36": "friuli venezia giulia",
    "IT-88": "sardegna",
    "IT-82": "sicilia",
    "IT-32": "trentino alto adige",
    "IT-23": "valle d'aosta"
};

export default regions;
