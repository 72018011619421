import React, { ReactNode } from 'react';
import {Box, Heading, Text} from 'grommet';
import moment from 'moment';
import styles from './styles'
import Notification from '../../models/notification';
import LanguageManager from '../../backend/LanguageManager';

interface Props {
    news: Notification;
    onClick?: (news: Notification)=>void;
    icon?: ReactNode,
}

const News: React.FC<Props> = props => {
    return (
        <Box style={styles.box} round="small" background="news" onClick={()=>props.onClick && props.onClick(props.news)}>
            <Box style={styles.header} responsive animation="slideDown">
                {LanguageManager.get(props.news.entity)}
            </Box>
            <Text>{moment(props.news.createdAt).format('L')}</Text>
            <Box direction="row" align="center" justify="between">
                <Box pad="small" style={styles.icon}>
                    {props.icon && props.icon}
                </Box>
                <Heading level="3">{props.news.content}</Heading>
            </Box>
            <Box pad="medium" >
                <Heading level="4">{props.news.description}</Heading>
            </Box>
        </Box>
    );
};

export default News;
