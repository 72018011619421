import React, { ReactNode } from 'react'
import { Box, Header, Heading } from 'grommet'
import styles from '../notFound/styles'

interface Props {
    children: ReactNode;
    entity?: Record<string, any> | null;
}

const ContextPage = (props: Props) => {
    return (
        <Box direction="column"> 
            {props.entity && (
                <Header style={styles.header} background="donatoni_1" responsive width={{min: '100%'}}>
                    <Heading level="2" responsive style={styles.headerText}>{props.entity ? props.entity.name ? props.entity.name : props.entity.title : ''}</Heading>
                </Header>
            )}
            <Box pad="small">
                {props.children}
            </Box>
        </Box>
    )
}

export default ContextPage
