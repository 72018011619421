import React, { useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { SET_ENTITY_SECTIONS } from '../../store/actions';
import LanguageManager from '../../backend/LanguageManager';

const MachinesPage: React.FC = props => {
    const machines = useSelector((state: State) => state.machines)   

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: []})
        // eslint-disable-next-line
    }, [])
    
    return <EntityPage entityName="machine" pageTitle={LanguageManager.get('machines', true)} entities={machines} />
};

export default MachinesPage;
