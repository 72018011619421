import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Attachment from '../models/attachment/attachment';

export default class AttachmentsController {
    public static async create(attachment: Attachment): Promise<Attachment> {
        try {
            return (await axios.post(ApiHelper.getEndpoint(RouteName.ATTACHMENTS), attachment, {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Impossibile caricare l\'allegato')
        }
    }

    public static async get(id: number): Promise<Attachment> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.ATTACHMENTS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Impossibile scaricare l\'allegato')
        }
    }

    public static getContentLink(id: number): string {
        return ApiHelper.getEndpoint(RouteName.ATTACHMENTS, id) + '/content?token=' + ApiHelper.token
    }

    public static async delete(id: number): Promise<number> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.ATTACHMENTS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Impossibile eliminare l\'allegato')
        }
    }
}