import User from "./user";

export enum Action {
    created = 1,
    updated,
    deleted
}

export default interface Notification {
    id: number;
    entity: string;
    entity_id: number;
    action: Action;
    content: string;
    description: string;
    user: User;
    createdAt: Date;
}