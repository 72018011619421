const en = {
    machine: "Machine",
    software: "Software",
    software_module: "Software module",
    optional: "Optional",
    bench: "bench",
    working: "Working",
    service: "Service",
    reference: "Case history",
    machines: "machines",
    softwares: "softwares",
    software_modules: "software modules",
    optionals: "optionals",
    benches: "benches",
    workings: "workings and simulations",
    services: "services",
    references: "case history",
    news: "last news",
    history: "history",
    world: "in the world",
    language: "Language",
    tags: "Tags",
    load_systems: "Loading/unloading systems",
    attachments: "Attachments",
    photos: "Photos",
    documents: "Documents",
    generic_documents: "Generic documents",
    user_menu: 'user menu',
    select_language: 'Select the language to use',
    italian: 'italian',
    english: 'english',
    benches_of_machine: 'benches of machine',
    loadSystem_of_machine: 'Load system of machine',
    optionals_of_machine: 'optionals of machine',
    references_of_machine: 'case history of machine',
    softwares_of_machine: 'softwares of machine',
    workings_of_machine: 'workings and simulations of machine',
    machines_of_optional: 'machines of optional',
    machines_of_reference: 'machines of case history',
    machines_of_software: 'machines of software',
    modules_of_software: 'modules of software',
    machines_of_workings: 'machines bind with',
    related: 'related',
    home: "home",
    back: "back",
    search: 'search',
    description: 'description',
    videos: 'videos'
};

export default en;
