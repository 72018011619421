import React, { useState } from 'react'
import Attachment from '../../models/attachment/attachment'
import { Box, Layer, Image, Heading, Button, Carousel } from 'grommet'
import BoxGrid, { BoxGridElement } from '../boxGrid'
import { FaTimes } from 'react-icons/fa';
import AttachmentsController from '../../backend/AttachmentsController';
import { useSelector } from 'react-redux';
import { State } from '../../store/state';
import ContextPage from '../contextPage';

const rows = {
    small: ["flex"],
    medium: ["medium","medium","medium"],
};

const columns = {
    small: ["flex"],
    medium: ["flex", "flex", "flex"],
    large: ["flex", "flex", "flex", "flex"],
};

interface Props {
    entityName?: string
    photos: Attachment[]
}

const PhotoGallery = (props: Props) => {
    const [photoElement, setPhotoElement] = useState<BoxGridElement>()
    const {entity} = useSelector((state: State) => state)
    
    const buildElements = (photos: Attachment[]) => {
        const elements: BoxGridElement[] = [];

        for (let i = 0; i < photos.length; i++) {
            elements.push({
                imageId: photos[i].id,
                id: i,
            })
        }

        return elements;
    }

    return (
        <ContextPage entity={entity}>
            <BoxGrid rows={rows} 
                columns={columns} 
                elementBackground="donatoni_black"
                elements={buildElements(props.photos)}
                onClick={(element: BoxGridElement)=>setPhotoElement(element)} /> 
            {photoElement && (
                <Layer 
                    margin="medium"
                    modal
                    full
                    onClickOutside={()=>setPhotoElement(undefined)}
                    onEsc={()=>setPhotoElement(undefined)}>
                    <Box
                        overflow="hidden"
                        pad="medium"
                        responsive
                        fill
                    >
                        <Box flex={false} direction="row" justify="between">
                            <Heading level={2} margin="none">
                                {props.entityName}
                            </Heading>
                            <Button icon={<FaTimes />} onClick={()=>setPhotoElement(undefined)} />
                        </Box>
                        <Carousel initialChild={photoElement.id} fill>
                            {props.photos.map((photo, index) => <Image key={index} fill fit="contain" src={AttachmentsController.getContentLink(photo.id)} />)}
                        </Carousel>
                    </Box>
                </Layer>
            )}
        </ContextPage>
    );
}

export default PhotoGallery
