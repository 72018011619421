import React from 'react'
import { useSelector } from 'react-redux';
import { State } from '../store/state';
import VideoShow from '../components/videoShow';
import Gallery from '../components/gallery';

const VideoGalleryPage = () => {
    const entity = useSelector((state: State) => state.entity)

    const handleGetContent = (videoId: string) => <VideoShow videoId={videoId} />

    return entity && entity.videos ? <Gallery items={JSON.parse(entity.videos)} getContent={handleGetContent} /> : <div />
}

export default VideoGalleryPage
