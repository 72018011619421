import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    button: {
        fontSize: '3vh',
        marginBottom: '0.8vh',
        textAlign: 'center',
        padding: '2px 5px 2px 0',
        marginLeft: '15px'
    },
}

export default styles;