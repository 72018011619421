const fr = {
    machine: "Macchina",
    software: "Software",
    software_module: "Modulo software",
    optional: "Optional",
    working: "Lavorazione",
    service: "Servizio",
    reference: "Referenza"
}

export default fr;