import React, {useEffect} from 'react';
import './login.css';
import {RouteComponentProps} from 'react-router';
import User from '../../models/user';
import LoginForm from '../../components/LoginForm';
import LoginController from '../../backend/LoginController';
import { toast } from 'react-toastify';

export const LoginPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
    const handleLogin = async (event: any) => {
        const user: User = validate(event.value);
        if (user) {
            try {
                await LoginController.login(user.username, user.password);
                window.location.reload();
            } catch (error) {
                toast.error(error.message)
            }
        }
    };
    const validate = (values: any) => {
        console.log(values);
        if (values.username && values.username.length > 0 && values.password && values.password.length > 0) {
            return values;
        }
    };

    useEffect(() => {
        const validateUser = async () => {
            try {
                const user = await LoginController.verify()
                if(user){
                    props.history.push('/app');
                }
            } catch (error) {
                console.log(error.message)
            }
        };

        validateUser();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="login-container">
            <LoginForm onSubmit={handleLogin} />
        </div>
    );
};
