import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import Bench from '../../models/bench';
import BenchesController from '../../backend/benchesController';

const BenchViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {benches} = useSelector((state: State) => state)

    useEffect(() => {
        const getMachine = async () => {
            const currentBench = benches && benches.find((benches: Bench) => benches.id === +id);

            if(ApiHelper.language !== Language.it && currentBench && currentBench.parent_id){
                try {
                    const parentBench = await BenchesController.get(currentBench.parent_id);
                    parentBench.name = currentBench.name;
                    parentBench.description = currentBench.description;

                    dispatch({type: SET_ENTITY, entity: parentBench})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentBench});
            }
        }

        getMachine()
        // eslint-disable-next-line
    }, [benches])

    return <EntityView />
};

export default BenchViewPage;
