import React, { ReactNode, ReactNodeArray } from 'react';
import { Route, Switch } from 'react-router-dom';
import Section from '../config/section/section';
import { Authorizor } from './Authorizor';
import NotFound from './notFound/notFound';

interface Props {
    sections: Section[];
}

export const Routes = (props: Props) => {
    const renderElement = (section: Section) => <Authorizor ComponentToValidate={section.route.component} {...section.route.componentProps} />

    const getRoute = (section: Section, index: string): ReactNode => {
        const sectionNode: ReactNodeArray = [];

        if (section.route) {
            sectionNode.push(
                <Route key={section.index} exact path={section.route.path} render={() => renderElement(section)} />
            );
        }

        if (section.subSections && section.subSections.length > 0) {
            section.subSections.map((subSection: Section, subIndex: number) =>
                sectionNode.push(getRoute(subSection, `${index}-${subIndex}`))
            );
        }

        return sectionNode;
    };

    return (
        <Switch>
            {props.sections.map((section: Section, index: any) => getRoute(section, index))}
            <Route key="not-found" component={NotFound} />
        </Switch>
    );
};
