import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import LoadSystem from '../../models/loadSystem';
import LoadSystemsContoller from '../../backend/loadSystemsController';

const LoadSystemViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {loadSystems} = useSelector((state: State) => state)

    useEffect(() => {
        const getMachine = async () => {
            const currentLoadSystem = loadSystems && loadSystems.find((loadSystems: LoadSystem) => loadSystems.id === +id);

            if(ApiHelper.language !== Language.it && currentLoadSystem && currentLoadSystem.parent_id){
                try {
                    const parentLoadSystem = await LoadSystemsContoller.get(currentLoadSystem.parent_id);
                    parentLoadSystem.name = currentLoadSystem.name;
                    parentLoadSystem.description = currentLoadSystem.description;

                    dispatch({type: SET_ENTITY, entity: parentLoadSystem})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentLoadSystem});
            }
        }

        getMachine()
        // eslint-disable-next-line
    }, [loadSystems])

    return <EntityView />
};

export default LoadSystemViewPage;
