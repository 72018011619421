import React, { ReactNode } from 'react'
import BoxGrid, { BoxGridElement } from '../boxGrid'
import { useSelector } from 'react-redux';
import { State } from '../../store/state';
import ContextPage from '../contextPage';

const rows = {
    small: ["flex"],
    medium: ["flex","flex","flex"],
};

const columns = {
    small: ["flex"],
    medium: ["flex", "flex", "flex"],
    large: ["flex", "flex", "flex", "flex"],
};

interface Props {
    items: any[];
    getTitle?: (item: any) => string;
    getContent: (item: any) => ReactNode;
    elementBackground?: string;
}

const Gallery = (props: Props) => {
    const {entity} = useSelector((state: State) => state)
    
    const buildElements = (items: any[]) => {
        const elements: BoxGridElement[] = [];

        for (let i = 0; i < items.length; i++) {
            elements.push({
                title: props.getTitle && props.getTitle(items[i]),
                content: props.getContent && props.getContent(items[i]),
            })
        }

        return elements;
    }

    return (
        <div>
            <ContextPage entity={entity}>
                <BoxGrid rows={rows} 
                    columns={columns} 
                    elementBackground={props.elementBackground ? props.elementBackground : "donatoni_black"}
                    elements={buildElements(props.items)} />
            </ContextPage>
        </div>
    )
}

export default Gallery
