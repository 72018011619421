import React from 'react';
import { Main, TextInput, Button as GrommetButton, Box, Text, Image } from 'grommet';
import { Routes } from './Routes';
import { useHistory } from 'react-router-dom';
import SearchSuggestion from './SearchSuggestion';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { State } from '../store/state';
import MachinesController from '../backend/MachinesController';
import SoftwaresController from '../backend/SoftwaresController';
import { SET_MACHINES, SET_SOFTWARES, SET_OPTIONALS, SET_MODULES, SET_POIS, SET_BENCHES, SET_WORKINGS, SET_REFERENCES, SET_SERVICES, SET_LOAD_SYSTEMS } from '../store/actions';
import OptionalsController from '../backend/OptionalsController';
import SoftwareModulesController from '../backend/SoftwareModulesController';
import { sections } from '../config/section/sections';
import ApiHelper from '../backend/apiHelper';
import { Language } from '../models/language';
import PoisController from '../backend/PoisController';
import BenchesController from '../backend/benchesController';
import WorkingsController from '../backend/WorkingsController';
import ReferencesController from '../backend/ReferencesController';
import ServiceController from '../backend/serviceController';
import SearchManager from '../backend/searchManager';
import LanguageManager from '../backend/LanguageManager';
import ContextSidebar from './contextSidebar';
import ModalLayer from './modalLayer';
import './main-styles.css';
import LoadSystemsContoller from '../backend/loadSystemsController';
import Button from './button';
import LoginController from '../backend/LoginController';


export const MainLayout: React.FC = props => {
    const getSearchParam = (paramName: string) => {
        const paramIndex = history.location.search.indexOf(paramName);
        if(paramIndex < 0){
            return false;
        }
    
        const value = history.location.search.substr(paramIndex+paramName.length+1)
        return value.split('&')[0]
    }
    
    const [suggestions, setSuggestions] = useState<any[]>([]);
    // const [showLanguage, setShowLanguage] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [showUserMenu, setShowUserMenu] = useState<boolean>(false)
    const history = useHistory();
    const { machines, softwares, optionals, benches, modules, workings, references, services, pois, user, entitySections, loadSystems, poiNavigation } = useSelector((state: State) => state)
    const searchEntities = [
        {
            name: 'machine', 
            list: machines
        },
        {
            name:'software',
            list: softwares
        },
        {
            name: 'optional',
            list: optionals,
        },
        {
            name: 'working',
            list: workings
        }];
        
    const dispatch = useDispatch();
        
    const isPoiNavigation = () => {
        return poiNavigation || +getSearchParam('poiNavigation') === 1;
    }

    useEffect(() => {
        const getAll = async () => {
            try {
                if (!machines || machines.length === 0) {
                    dispatch({ type: SET_MACHINES, machines: (await MachinesController.getAll()) })
                }
                if (!softwares || softwares.length === 0) {
                    dispatch({ type: SET_SOFTWARES, softwares: (await SoftwaresController.getAll()) })
                }
                if (!optionals || optionals.length === 0) {
                    dispatch({ type: SET_OPTIONALS, optionals: (await OptionalsController.getAll()) })
                }
                if (!modules || modules.length === 0) {
                    dispatch({ type: SET_MODULES, modules: (await SoftwareModulesController.getAll()) })
                }
                if (!pois || pois.length === 0) {
                    dispatch({ type: SET_POIS, pois: (await PoisController.getAll()) })
                }
                if (!benches || benches.length === 0) {
                    dispatch({ type: SET_BENCHES, benches: (await BenchesController.getAll()) })
                }
                if (!workings || workings.length === 0) {
                    dispatch({ type: SET_WORKINGS, workings: (await WorkingsController.getAll()) })
                }
                if (!references || references.length === 0) {
                    dispatch({ type: SET_REFERENCES, references: (await ReferencesController.getAll()) })
                }
                if (!services || services.length === 0) {
                    dispatch({ type: SET_SERVICES, services: (await ServiceController.getAll()) })
                }
                if (!loadSystems || loadSystems.length === 0) {
                    dispatch({ type: SET_LOAD_SYSTEMS, loadSystems: (await LoadSystemsContoller.getAll()) })
                }
            } catch (error) {
                console.log(error)
            }
        }

        getAll()
        // eslint-disable-next-line
    }, [])

    const handleLogout = () => LoginController.logout();

    // const showLanguageSelector = () => setShowLanguage(true)

    const setLanguage = (lang: Language) => {
        ApiHelper.language = lang
        // setShowLanguage(false);
        history.push('/app');
        window.location.reload();
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        let suggestions: any[] = [];

        for (const searchEntity of searchEntities) {
            const entitySuggestions = SearchManager.startSearch(searchEntity.list, event.target.value).map((entity: any) => ({
                type: searchEntity.name,
                link: `/app/${searchEntity.name}/${entity.id}`,
                label: <SearchSuggestion suggestionName={entity.name} typeName={LanguageManager.get(searchEntity.name)} />,
            }))

            if(entitySuggestions && entitySuggestions.length > 0){
                suggestions = suggestions.concat(entitySuggestions)
            }
        }

        setSuggestions(suggestions);
    };

    const handleSuggestionSelection = (event: any) => {
        setShowSearch(false);
        history.push(event.suggestion.link);
    };

    return (
        
        <Box fill className='main-container-box'>
            <ContextSidebar entitySections={entitySections} user={user} onAvatarClick={()=>setShowUserMenu(true)} onSearchClick={()=>setShowSearch(true)} poiNavigation={isPoiNavigation()} firstPage={+getSearchParam('firstPage') === 1} />
            <Main fill>
                <Routes sections={sections} />
                {props.children}
            </Main>
            {showUserMenu && (
                <ModalLayer
                    onClose={() => setShowUserMenu(false)}
                    title={LanguageManager.get('user_menu', true)}
                >
                    <React.Fragment>
                        <Box direction="column" pad="large" responsive fill align="start">
                            <Text>{LanguageManager.get('select_language', true)}</Text>
                            <Box direction="row" pad="small" responsive fill justify="evenly" align="center" style={{width: '80vw'}}>
                                <Button
                                    title={LanguageManager.get('italian', true)} 
                                    icon={<Image alt="Italy" height="50" style={{padding: '20px'}} src="/img/flags/IT.svg"/>} 
                                    onClick={()=>setLanguage(Language.it)} />
                                <Button 
                                    title={LanguageManager.get('english', true)}
                                    icon={<Image alt="Great britain" height="50" style={{padding: '20px'}} src="/img/flags/GB.svg"/>}
                                    onClick={()=>setLanguage(Language.en)} />
                            </Box>
                            <GrommetButton primary style={{padding: '10px'}} color="donatoni_1" alignSelf="end" onClick={handleLogout}>Logout</GrommetButton>
                        </Box>
                    </React.Fragment>
                </ModalLayer>
            )}
            {showSearch && (
                <ModalLayer 
                    onClose={() => setShowSearch(false)}
                    title="Ricerca"
                >
                    <React.Fragment>
                        <Text>Digita uno o più termini di per avviare la ricerca.</Text>
                        <TextInput
                            placeholder="Ricerca"
                            onChange={handleSearch}
                            onSelect={handleSuggestionSelection}
                            suggestions={suggestions}
                        />
                    </React.Fragment>
                </ModalLayer>
            )}
        </Box>
    );
};
