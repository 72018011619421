import { Box, Heading } from 'grommet';
import React, { useEffect } from 'react'
import { FaFile, FaImage } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import ExtraEntityController from '../../backend/extraEntityController';
import LanguageManager from '../../backend/LanguageManager';
import Button from '../../components/button';
import Grid from '../../components/grid';
import { SET_ENTITY } from '../../store/actions';

const rows = {
    small: ["flex"],
};

const columns = {
    small: ["flex"],
    medium: ["flex", "flex"],
};

export const ExtraAttachments = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    
    const getExtra = async () => {
        try {
            dispatch({type: SET_ENTITY, entity: ((await ExtraEntityController.getAll())[0])});
        } catch (error) {
            toast.warn(error.message)
        }
    }

    useEffect(() => {
        getExtra();
        // eslint-disable-next-line
    }, [])

    return (
        <Box pad="large" direction="column" height={{min: '100vh'}}>
            <Heading>{LanguageManager.get('generic_documents')}</Heading>
            <Grid rows={rows} columns={columns} >
                <Button title={LanguageManager.get('photos', true)} onClick={()=>history.push("/app/photogallery")} icon={<FaImage />}  />
                <Button title={LanguageManager.get('documents', true)} onClick={()=>history.push("/app/documents")} icon={<FaFile />}  />
            </Grid>
        </Box>
    )
}
