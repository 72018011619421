import { ArcRotateCamera, Vector3, Scene, FramingBehavior } from "@babylonjs/core";

export default class Camera {
    constructor(scene: Scene) {
        const camera = new ArcRotateCamera(
            "arc",
            0,
            0,
            0,
            new Vector3(0, 0, 0),
            scene
        );
        
        camera.upperBetaLimit = Math.PI;
        camera.wheelPrecision = 75;
        camera.useNaturalPinchZoom = true;
        camera.useAutoRotationBehavior = false;        
        camera.maxZ = 100000;

        const canvas: any = scene.getEngine().getRenderingCanvas();
        camera.attachControl(canvas);

        camera.useFramingBehavior = true;
        if(camera.framingBehavior){
            camera.framingBehavior.mode = FramingBehavior.IgnoreBoundsSizeMode
            camera.framingBehavior.elevationReturnTime = -1;
            camera.framingBehavior.framingTime = 0;
            camera.framingBehavior.elevationReturnTime = -1;
        }
    }
}
