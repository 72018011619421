import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import Optional from '../../models/optional/optional';
import Machine from '../../models/product/machine';
import LanguageManager from '../../backend/LanguageManager';

const MachineOptionalPage: React.FC = () => {
    const [machine, setMachine] = useState<Machine>()
    const [machineOptionals, setMachineOptionals] = useState<Optional[]>([])
    const {optionals, entity} = useSelector((state:State) => state)

    useEffect(() => {
        const getMachineOptionals = async () => {
            if(entity && optionals){
                const optionalsIds: number[] = entity.optionals ? entity.optionals.map((optional:Optional) => optional.id) : []
                setMachineOptionals(optionals.filter(optional => optionalsIds.includes(optional.parent_id ?? optional.id)))
                setMachine(machine)
            }
        }

        getMachineOptionals()
        // eslint-disable-next-line
    }, [optionals])

    return <EntityPage pageTitle={LanguageManager.get('optionals_of_machine', true)+` ${machine ? machine.name : ''}`} entityName='optional' entities={machineOptionals} />
};

export default MachineOptionalPage;
