import Section from "./section";
import { Dashboard } from "../../pages/Dashboard";
import MachinesPage from "../../pages/machines";
import OptionalPage from "../../pages/optionals";
import SoftwarePage from "../../pages/softwares";
import MachineViewPage from "../../pages/machines/view";
import OptionalViewPage from "../../pages/optionals/view";
import SoftwareViewPage from "../../pages/softwares/view";
import Machine3DPage from "../../pages/machines/3d";
import PhotoGalleryPage from "../../pages/photogallery";
import VideoGalleryPage from "../../pages/videogallery";
import DocumentsPage from "../../pages/documents";
import MachineOptionalPage from "../../pages/machines/optionals";
import MachineSoftwarePage from "../../pages/machines/softwares";
import MachineBenchesPage from "../../pages/machines/benches";
import BenchViewPage from "../../pages/benches/view";
import SoftwareModulesPage from "../../pages/softwares/modules";
import SoftwareModulesViewPage from "../../pages/softwareModules/view";
import WorkingViewPage from "../../pages/workings/view";
import ReferenceViewPage from "../../pages/references/view";
import MachineWorkingsPage from "../../pages/machines/workings";
import MachineReferencesPage from "../../pages/machines/references";
import WorkingsPage from "../../pages/workings";
import ServiceViewPage from "../../pages/services/view";
import ServicesPage from "../../pages/services";
import PoiViewPage from "../../pages/pois/view";
import Welcome from "../../pages/welcome";
import ReferencesPage from "../../pages/references";
import NewsPage from "../../pages/news";
import WorkingMachinesPage from "../../pages/workings/machines";
import ReferenceMachinesPage from "../../pages/references/machines";
import OptionalMachinesPage from "../../pages/optionals/machines";
import SoftwareMachinesPage from "../../pages/softwares/machines";
import HistoryPage from "../../pages/history";
import WorldPage from "../../pages/world";
import { Related } from "../../pages/related";
import SoftwareModulesMainPage from "../../pages/softwareModules";
import BenchesPage from "../../pages/benches";
import LoadSystemsPage from "../../pages/loadSystems";
import LoadSystemViewPage from "../../pages/loadSystems/view";
import MachineLoadSystemsPage from "../../pages/machines/loadSystems";
import { ExtraAttachments } from "../../pages/extraAttachments";

export const sections: Section[] = [
    {
        name: "Welcome",
        index: 0,
        route: {
            path: "/app",
            component: Welcome,
            componentProps: {}
        },
        icon: "dashboard"
    },
    {
        name: "Storia",
        index: 100,
        route: {
            path: "/app/history",
            component: HistoryPage,
            componentProps: {}
        },
        icon: "dashboard"
    },
    {
        name: "Dashboard",
        index: 1,
        route: {
            path: "/app/dashboard",
            component: Dashboard,
            componentProps: {}
        },
        icon: "dashboard"
    },
    {
        name: "Avvisi",
        index: 6,
        route: {
            path: "/app/news",
            component: NewsPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Macchine",
        index: 21,
        route: {
            path: "/app/machines",
            component: MachinesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Macchina",
        index: 22,
        route: {
            path: "/app/machine/:id",
            component: MachineViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Optional della Macchina",
        index: 23,
        route: {
            path: "/app/machine/:id/optionals",
            component: MachineOptionalPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Software della Macchina",
        index: 24,
        route: {
            path: "/app/machine/:id/softwares",
            component: MachineSoftwarePage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Sistemi di carico/scarico della Macchina",
        index: 28,
        route: {
            path: "/app/machine/:id/load-systems",
            component: MachineLoadSystemsPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Banchi della Macchina",
        index: 25,
        route: {
            path: "/app/machine/:id/benches",
            component: MachineBenchesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Lavorazioni Macchina",
        index: 26,
        route: {
            path: "/app/machine/:id/workings",
            component: MachineWorkingsPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Referenze Macchina",
        index: 27,
        route: {
            path: "/app/machine/:id/references",
            component: MachineReferencesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Photo gallery",
        index: 7,
        route: {
            path: "/app/photogallery",
            component: PhotoGalleryPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Video gallery",
        index: 8,
        route: {
            path: "/app/videogallery",
            component: VideoGalleryPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Documents",
        index: 9,
        route: {
            path: "/app/documents",
            component: DocumentsPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Visualizza 3D macchina",
        index: 23,
        route: {
            path: "/app/machine/:id/3d",
            component: Machine3DPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Optionals",
        index: 3,
        route: {
            path: "/app/optionals",
            component: OptionalPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Optional",
        index: 31,
        route: {
            path: "/app/optional/:id",
            component: OptionalViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Macchine associate a Optional",
        index: 32,
        route: {
            path: "/app/optional/:id/machines",
            component: OptionalMachinesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Softwares",
        index: 4,
        route: {
            path: "/app/softwares",
            component: SoftwarePage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Software",
        index: 41,
        route: {
            path: "/app/software/:id",
            component: SoftwareViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Macchine associate a software",
        index: 42,
        route: {
            path: "/app/software/:id/machines",
            component: SoftwareMachinesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Moduli del software",
        index: 45,
        route: {
            path: "/app/software/:id/modules",
            component: SoftwareModulesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Banchi",
        index: 50,
        route: {
            path: "/app/benches",
            component: BenchesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Banco",
        index: 51,
        route: {
            path: "/app/bench/:id",
            component: BenchViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Moduli software",
        index: 60,
        route: {
            path: "/app/software-modules",
            component: SoftwareModulesMainPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Modulo software",
        index: 61,
        route: {
            path: "/app/software-module/:id",
            component: SoftwareModulesViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Lavorazioni",
        index: 7,
        route: {
            path: "/app/workings",
            component: WorkingsPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Lavorazione",
        index: 71,
        route: {
            path: "/app/working/:id",
            component: WorkingViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Lavorazione",
        index: 72,
        route: {
            path: "/app/working/:id/machines",
            component: WorkingMachinesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Referenze",
        index: 8,
        route: {
            path: "/app/references",
            component: ReferencesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Referenza",
        index: 81,
        route: {
            path: "/app/reference/:id",
            component: ReferenceViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Macchine referenza",
        index: 82,
        route: {
            path: "/app/reference/:id/machines",
            component: ReferenceMachinesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Servizi",
        index: 9,
        route: {
            path: "/app/services",
            component: ServicesPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Servizio",
        index: 91,
        route: {
            path: "/app/service/:id",
            component: ServiceViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Poi",
        index: 91,
        route: {
            path: "/app/poi/:id",
            component: PoiViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "World",
        index: 15,
        route: {
            path: "/app/world",
            component: WorldPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Related",
        index: 101,
        route: {
            path: "/app/related",
            component: Related,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Sistemi di carico/scarico",
        index: 110,
        route: {
            path: "/app/load-systems",
            component: LoadSystemsPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Sistema di carico/scarico",
        index: 111,
        route: {
            path: "/app/load-system/:id",
            component: LoadSystemViewPage,
            componentProps: {}
        },
        icon: ""
    },
    {
        name: "Allegati extra",
        index: 120,
        route: {
            path: "/app/extra-attachments",
            component: ExtraAttachments,
            componentProps: {}
        },
        icon: ""
    }
    // your section here
];
