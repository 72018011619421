import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Software from '../../models/product/software';
import SoftwareModule from '../../models/optional/softwareModule';
import SoftwaresController from '../../backend/SoftwaresController';
import LanguageManager from '../../backend/LanguageManager';

const SoftwareModulesPage: React.FC = () => {
    const {id} = useParams();
    const [software, setSoftware] = useState<Software>()
    const [softwareModules, setSoftwareModules] = useState<SoftwareModule[]>([])
    const {modules} = useSelector((state:State) => state)

    const getSoftware = async () => {
        try {
            return await SoftwaresController.get(id);
        } catch (error) {
            toast.warn(error.message)
        }
    }

    useEffect(() => {
        const getSoftwareModules = async () => {
            const software = await getSoftware()
            if(software && modules){
                const modulesIds: number[] = software.modules ? software.modules.map((module:SoftwareModule) => module.id) : []
                setSoftwareModules(modules.filter(module => modulesIds.includes(module.id)))
                setSoftware(software)
            }
        }

        getSoftwareModules()
        // eslint-disable-next-line
    }, [modules])

    return <EntityPage pageTitle={LanguageManager.get('modules_of_software', true)+` ${software ? software.name : ''}`} entityName='software-module' entities={softwareModules} />
};

export default SoftwareModulesPage;
