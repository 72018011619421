import React from 'react'
import { Box, Heading } from 'grommet'
import BoxGrid, { BoxGridElement } from '../boxGrid'
import ContextPage from '../contextPage';

interface Props {
    pageTitle: string;
    entityName: string;
    entities?: Record<string, any>[] | null;
    titleField?: string;
    loadPhotos?: boolean;
}

const EntityPage = (props: Props) => {
    const buildBoxViewElements = (entities: Record<string, any>[] | undefined | null): BoxGridElement[] =>
        entities
            ? entities.map((entity: Record<string, any>) => {
                let imageId;

                // sceglie casualmente una foto se disponibile oppure la sceglie tra quelle del parent
                if(entity.photos && entity.photos.length > 0 ){
                    imageId = entity.photos[Math.floor((Math.random() * 1000) % entity.photos.length)].id;
                } else if (entity.parent_id && entity.parent.photos && entity.parent.photos.length > 0) {
                    imageId = entity.parent.photos[Math.floor((Math.random() * 1000) % entity.parent.photos.length)].id;
                }

                return {
                    title: entity[props.titleField ? props.titleField : 'name'],
                    link: `/app/${props.entityName}/${entity.id}`,
                    id: entity.id,
                    imageId
                };
            })
            : [];

    return (
        <div>
            <ContextPage>
                <Box pad="small">
                    <Heading>{props.pageTitle}</Heading>
                    <BoxGrid elements={buildBoxViewElements(props.entities)} />
                </Box>
            </ContextPage>
        </div>
    )
}

export default EntityPage
