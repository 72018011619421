import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import SoftwareModule from '../models/optional/softwareModule';

export default class SoftwareModulesController {
    public static async getAll(): Promise<SoftwareModule[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.SOFTWARE_MODULES), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni dei moduli software')
        }
    }

    public static async create(optional: SoftwareModule): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.SOFTWARE_MODULES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione del modulo software')
        }
    }

    public static async update(optional: SoftwareModule): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.SOFTWARE_MODULES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento del modulo software')
        }
    }

    public static async get(id: number): Promise<SoftwareModule> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.SOFTWARE_MODULES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni del modulo software')
        }
    }

    public static async delete(id: number): Promise<SoftwareModule> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.SOFTWARE_MODULES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione del modulo software')
        }
    }
}