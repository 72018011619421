import React, { ReactNode } from 'react'
import Grid from '../grid';
import Button from '../button';
import { useHistory } from 'react-router';

const rows = {
    small: ["flex"],
    medium: ["flex","flex","flex"],
};

const columns = {
    small: ["flex"],
    medium: ["flex"],
    large: ["flex", "flex", "flex"],
};

export interface BoxGridElement {
    title?: string;
    content?: ReactNode;
    link?: string;
    icon?: JSX.Element;
    id?: number;
    base64Image?: string;
    imageId?: number;
}

interface Props {
    rows?: Record<string, string[]>;
    columns?: Record<string, string[]>;
    elements: BoxGridElement[];
    onClick?: (element: BoxGridElement) => void,
    elementBackground?: string;
}

const BoxGrid = (props: Props) => {
    const history = useHistory();

    return (
        <Grid rows={props.rows ? props.rows : rows} columns={props.columns ? props.columns : columns}>
            {props.elements.map((element: BoxGridElement, index: number) => (
                <Button key={index} 
                    title={element.title}
                    content={element.content}
                    background={props.elementBackground && props.elementBackground}
                    icon={element.icon} 
                    imageId={element.imageId} 
                    onClick={()=>element.link ? history.push(element.link) : props.onClick && props.onClick(element)} />
            ))}  
        </Grid>
    )
}

export default BoxGrid
