const it = {
    machine: "macchina",
    software: "software",
    software_module: "modulo software",
    optional: "optional",
    bench: "banco",
    working: "lavorazione / simulazione",
    service: "servizio",
    reference: "referenza",
    machines: "macchine",
    softwares: "software",
    software_modules: "Moduli software",
    optionals: "Optional",
    benches: "Banchi",
    workings: "Lavorazioni e simulazioni",
    services: "Servizi",
    references: "Case history",
    news: "ultimi aggiornamenti",
    history: "storia/mission",
    world: "nel mondo",
    language: "Lingua",
    tags: "Collegamenti",
    load_systems: "Sistemi di carico/scarico",
    attachments: "Allegati",
    photos: "foto",
    documents: "documenti",
    generic_documents: "Documenti generali",
    user_menu: 'Menu utente',
    select_language: 'Seleziona la lingua da utilizzare',
    italian: 'italiano',
    english: 'inglese',
    benches_of_machine: 'banchi della macchina',
    loadSystem_of_machine: 'sistema di carico/scarico della macchina',
    optionals_of_machine: 'optional della macchina',
    references_of_machine: 'case history relative alla macchina',
    softwares_of_machine: 'software della macchina',
    workings_of_machine: 'lavorazioni eseguibili con la macchina',
    machines_of_optional: 'macchine associate all\'optional',
    machines_of_reference: 'macchine associate alla case history',
    machines_of_software: 'macchine associate al software',
    modules_of_software: 'moduli associati al software',
    machines_of_workings: 'macchine associate a',
    related: 'collegamenti',
    home: "home",
    back: "indietro",
    search: 'ricerca',
    description: 'descrizione',
    videos: 'video'
};

export default it;
