import React from 'react';
import './playground.css';
import { Vector3, Color3, Scene as BabylonScene, Color4, SceneOptimizerOptions, SceneOptimizer, MergeMeshesOptimization, SceneOptimization, PostProcessesOptimization} from '@babylonjs/core';
import { Engine, Scene, SceneEventArgs } from 'react-babylonjs'
import Poi from '../models/poi';
import Model from '../3d/Model';
import Camera from '../3d/Camera';

interface Props {
    machineId: number;
    pois: Poi[];
    modelUrl: string;
    onPoiClick: (scene: any, poiId: number | null, mesh: any) => void
    onSceneMonted?: (scene: BabylonScene) => void
}

const Playground: React.FC<Props> = (props: Props) => {
    const handleSceneMount = (sceneEventArgs: SceneEventArgs) => {
        const { scene } = sceneEventArgs;
        const bindedPois = []

        for (const poi of props.pois) {
            if (poi.machines.map(machine => machine.id).includes(+props.machineId)) {
                poi.machines = poi.machines.filter(machine => machine.id === +props.machineId)
                bindedPois.push(poi)
            }
        }

        new Camera(scene);
        const model = new Model(scene, props.modelUrl, bindedPois)
        model.buttonClickCallback = (poi: number | null, mesh: any)=> props.onPoiClick(scene, poi, mesh)
        model.load()

        const options = new SceneOptimizerOptions();
        options.addOptimization(new MergeMeshesOptimization(0));
        options.addOptimization(new SceneOptimization(0));
        options.addOptimization(new PostProcessesOptimization(0));

        // Optimizer
        new SceneOptimizer(scene, options);

        props.onSceneMonted && props.onSceneMonted(scene)
    };


    return (
        <div>
            <Engine canvasId="sample-canvas" babylonJSContext={{ canvas: null, engine: null }} antialias  canvasStyle={{marginRight: '60px'}}>
                <Scene onSceneMount={handleSceneMount} clearColor={new Color4(0.1,0.1,0.1)}>
                    <directionalLight name="direction1" direction={Vector3.Up()} diffuse={new Color3(1,1,1)} specular={new Color3(0.7,0,0)} />
                    <directionalLight name="direction2" direction={Vector3.Down()} diffuse={new Color3(1,1,1)} specular={new Color3(0.7,0,0)} />
                    <hemisphericLight name="hemi" intensity={0.6} direction={Vector3.Up()} diffuse={new Color3(0.9,0.9,1)}  /> 
                    <environmentHelper options={{
                        sizeAuto: true,
                        createSkybox: false
                    }} />
                </Scene>
            </Engine>
        </div>
    );
};

export default Playground;
