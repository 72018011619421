import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Machine from '../../models/product/machine';
import Software from '../../models/product/software';
import SoftwaresController from '../../backend/SoftwaresController';
import LanguageManager from '../../backend/LanguageManager';

const SoftwareMachinesPage: React.FC = () => {
    const {id} = useParams();
    const [software, setSoftware] = useState<Software>()
    const [softwareMachines, setSoftwareMachines] = useState<Machine[]>([])
    const {machines} = useSelector((state:State) => state)

    const get = async () => {
        try {
            return await SoftwaresController.get(id);
        } catch (error) {
            toast.warn(error.message)
        }
    }

    useEffect(() => {
        const getMachineOptionals = async () => {
            const software = await get()
            if(software && machines){
                const machinesIds: number[] = software.machines ? software.machines.map((machine:Machine) => machine.id) : []
                setSoftwareMachines(machines.filter(machine => machinesIds.includes(machine.id)))
                setSoftware(software)
            }
        }

        getMachineOptionals()
        // eslint-disable-next-line
    }, [machines])

    return <EntityPage pageTitle={LanguageManager.get('machines_of_software', true)+` ${software ? software.name : ''}`} entityName='machine' entities={softwareMachines} />
};

export default SoftwareMachinesPage;
