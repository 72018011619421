import React, { useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { SET_ENTITY_SECTIONS } from '../../store/actions';
import LanguageManager from '../../backend/LanguageManager';

const BenchesPage: React.FC = props => {
    const benches = useSelector((state: State) => state.benches)   

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: []})
        // eslint-disable-next-line
    }, [])
    
    return <EntityPage entityName="bench" pageTitle={LanguageManager.get('benches', true)} entities={benches} />
};

export default BenchesPage;
