export const SET_USER = "SET_USER";
export const SET_MACHINES = "SET_MACHINES";
export const SET_MACHINE = "SET_MACHINE";
export const SET_ENTITY = "SET_ENTITY";
export const SET_SOFTWARES = "SET_SOFTWARES";
export const SET_OPTIONALS = "SET_OPTIONALS";
export const SET_BENCHES = "SET_BENCHES";
export const SET_MODULES = "SET_MODULES";
export const SET_WORKINGS = "SET_WORKINGS";
export const SET_REFERENCES = "SET_REFERENCES";
export const SET_POIS = "SET_POIS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_ENTITY_SECTIONS = "SET_ENTITY_SECTIONS";
export const SET_LOAD_SYSTEMS = "SET_LOAD_SYSTEMS";
export const SET_POI_NAVIGATION = "SET_POI_NAVIGATION";
