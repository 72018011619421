export enum EntityType {
    MACHINE = 'machine',
    OPTIONAL = 'optional',
    BENCH = 'bench',
    SOFTWARE = 'software',
    SOFTWARE_MODULE = 'software-module',
    POI = 'poi',
    WORKING = 'working',
    SERVICE = 'service',
    REFERENCE = 'reference',
}