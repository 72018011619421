import React, { CSSProperties } from 'react'
import { EntityType } from '../../backend/entityType'
import { MdGroupWork, MdSettingsInputComponent, MdDashboard } from 'react-icons/md'
import { FaDesktop, FaBuffer, FaAward, FaQuestion, FaVectorSquare, FaMapMarker, FaPencilRuler } from 'react-icons/fa'

interface Props {
    type: EntityType,
    style?: CSSProperties
}

const EntityIcon = (props: Props) => {
    const getIcon = () => {
        switch (props.type) {
        case EntityType.MACHINE:
            return <MdGroupWork />      
        case EntityType.OPTIONAL:      
            return <MdSettingsInputComponent />      
        case EntityType.BENCH:                
            return <FaVectorSquare />      
        case EntityType.SOFTWARE:                
            return <FaDesktop />      
        case EntityType.SOFTWARE_MODULE:
            return <MdDashboard />      
        case EntityType.POI:
            return <FaMapMarker />      
        case EntityType.WORKING:
            return <FaPencilRuler />      
        case EntityType.SERVICE:
            return <FaBuffer />      
        case EntityType.REFERENCE:
            return <FaAward />      
        default:
            return <FaQuestion />      
        }
    }

    return (
        <div style={props.style}>
            {getIcon()}
        </div>
    )
}

export default EntityIcon
