import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    container: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '6.4vh',
        margin: '0px',
        minHeight: '100vh',
    },
    mobileContainer: {
        width: '100%'
    },
    mobileBar: {
        background: '#FFFFFFef',
        width: '100vw',
        position: 'fixed',
        top: '0px',
        border: '1px dotted #005AA5',
        zIndex: 9
    },
    button: {
        fontSize: '3vh',
        padding: '.3vh',
        marginBottom: '1vh'
    },
    version: {
        textAlign: 'center'
    },
    avatar: {
        zIndex: 1,
        width: '5.5vh', 
        height: '5.5vh', 
        margin: '.5vh'
    }
}

export default styles;