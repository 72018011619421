import axios from "axios";
import ApiHelper, { RouteName } from "./apiHelper";
import { Worldzone } from "../models/worldzone";

export default class WorldzoneController {
    public static async getAll(): Promise<Worldzone[]> {
        try {
            return (
                await axios.get(ApiHelper.getEndpoint(RouteName.WORLDZONES), {
                    headers: ApiHelper.headers
                })
            ).data;
        } catch (error) {
            throw new Error("Errore durante il download delle wordlzone");
        }
    }

    public static async getRegions(countryCode: string): Promise<any[]> {
        try {
            return (
                await axios.get(
                    ApiHelper.getEndpoint(RouteName.WORLDZONES) +
                        `/regions/${countryCode}`,
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle macchine di " + countryCode
            );
        }
    }

    public static async getMachines(countryCode: string): Promise<any[]> {
        try {
            return (
                await axios.get(
                    ApiHelper.getEndpoint(RouteName.WORLDZONES) +
                        `/machines/${countryCode}`,
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle macchine di " + countryCode
            );
        }
    }

    public static async getMachinesByRegion(
        regionCode: string
    ): Promise<any[]> {
        try {
            return (
                await axios.get(
                    ApiHelper.getEndpoint(RouteName.WORLDZONES) +
                        `/machines/region/${regionCode}`,
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle macchine della regione " +
                    regionCode
            );
        }
    }

    public static async getRegionsByMachine(
        machineName: string
    ): Promise<any[]> {
        try {
            return (
                await axios.post(
                    ApiHelper.getEndpoint(RouteName.WORLDZONES) +
                        `/regions/machine`,
                    { machineName },
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle regione associate alla macchina " +
                    machineName
            );
        }
    }

    public static async getAllCounts(): Promise<[]> {
        try {
            return (
                await axios.get(
                    ApiHelper.getEndpoint(RouteName.WORLDZONES) + "/counts",
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle informazioni delle wordlzone"
            );
        }
    }
}
