import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import Poi from '../../models/poi';
import PoisController from '../../backend/PoisController';

const PoiViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {pois} = useSelector((state: State) => state)

    useEffect(() => {
        const getMachine = async () => {
            const currentPoi = pois && pois.find((poi: Poi) => poi.id === +id);

            if(ApiHelper.language !== Language.it && currentPoi && currentPoi.parent_id){
                try {
                    const parentPoi = await PoisController.get(currentPoi.parent_id);
                    parentPoi.title = currentPoi.title;
                    parentPoi.description = currentPoi.description;

                    dispatch({type: SET_ENTITY, entity: parentPoi})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentPoi});
            }
        }

        getMachine()
        // eslint-disable-next-line
    }, [pois])

    return <EntityView />
};

export default PoiViewPage;
