import React, { useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { SET_ENTITY_SECTIONS } from '../../store/actions';
import LanguageManager from '../../backend/LanguageManager';

const SoftwareModulesMainPage: React.FC = props => {
    const modules = useSelector((state: State) => state.modules)   

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: []})
        // eslint-disable-next-line
    }, [])
    
    return <EntityPage entityName="software-module" pageTitle={LanguageManager.get('software_modules', true)} entities={modules} />
};

export default SoftwareModulesMainPage;
