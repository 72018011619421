import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        padding: '1em',
        opacity: '60%',
    }
}

export default styles;