import axios from "axios";
import Machine from "../models/product/machine";
import ApiHelper, { RouteName } from "./apiHelper";
import { Language } from "../models/language";

export default class MachinesController {
    public static async getAll(): Promise<Machine[]> {
        try {
            return (
                await axios.get(ApiHelper.getEndpoint(RouteName.MACHINES), {
                    headers: ApiHelper.headers
                })
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle informazioni sulle macchine!"
            );
        }
    }

    public static async create(machine: Machine): Promise<void> {
        try {
            await axios.post(
                ApiHelper.getEndpoint(RouteName.MACHINES),
                machine,
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error("Errore durante la creazione della macchina");
        }
    }

    public static async update(machine: Machine): Promise<void> {
        try {
            await axios.put(
                ApiHelper.getEndpoint(RouteName.MACHINES),
                machine,
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error("Errore durante l'aggiornamento della macchina");
        }
    }

    public static async get(id: number): Promise<Machine> {
        try {
            return (
                await axios.get(ApiHelper.getEndpoint(RouteName.MACHINES, id), {
                    headers: ApiHelper.headers
                })
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle informazioni della macchina"
            );
        }
    }

    public static async getTranslation(
        parentId: number,
        language: Language
    ): Promise<Machine> {
        try {
            return (
                await axios.get(
                    ApiHelper.getEndpoint(RouteName.MACHINES, parentId) +
                        "/translation/" +
                        language,
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error(
                "Errore durante il download delle informazioni della macchina"
            );
        }
    }

    public static async delete(id: number): Promise<Machine> {
        try {
            return (
                await axios.delete(
                    ApiHelper.getEndpoint(RouteName.MACHINES, id),
                    {
                        headers: ApiHelper.headers
                    }
                )
            ).data;
        } catch (error) {
            throw new Error("Errore durante l'eliminazione della macchina");
        }
    }

    public static async bindOptional(
        machineId: number,
        optionalId: number
    ): Promise<void> {
        try {
            await axios.post(
                ApiHelper.getEndpoint(RouteName.MACHINES, machineId) +
                    "/optional",
                { optionalId: optionalId },
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error(
                "Errore durante l'associazione dell'optional alla macchina"
            );
        }
    }

    public static async unbindOptional(
        machineId: number,
        optionalId: number
    ): Promise<void> {
        try {
            await axios.delete(
                ApiHelper.getEndpoint(RouteName.MACHINES, machineId) +
                    "/optional/" +
                    optionalId,
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error(
                "Errore durante l'eliminazione dell'associazione tra optional e la macchina"
            );
        }
    }

    public static async bindBench(
        machineId: number,
        benchId: number
    ): Promise<void> {
        try {
            await axios.post(
                ApiHelper.getEndpoint(RouteName.MACHINES, machineId) + "/bench",
                { benchId: benchId },
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error(
                "Errore durante l'associazione del banco alla macchina"
            );
        }
    }

    public static async unbindBench(
        machineId: number,
        benchId: number
    ): Promise<void> {
        try {
            await axios.delete(
                ApiHelper.getEndpoint(RouteName.MACHINES, machineId) +
                    "/bench/" +
                    benchId,
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error(
                "Errore durante l'eliminazione dell'associazione tra il banco e la macchina"
            );
        }
    }

    public static async bindSoftware(
        machineId: number,
        softwareId: number
    ): Promise<void> {
        try {
            await axios.post(
                ApiHelper.getEndpoint(RouteName.MACHINES, machineId) +
                    "/software",
                { softwareId: softwareId },
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error(
                "Errore durante l'associazione del software alla macchina"
            );
        }
    }

    public static async unbindSoftware(
        machineId: number,
        softwareId: number
    ): Promise<void> {
        try {
            await axios.delete(
                ApiHelper.getEndpoint(RouteName.MACHINES, machineId) +
                    "/software/" +
                    softwareId,
                {
                    headers: ApiHelper.headers
                }
            );
        } catch (error) {
            throw new Error(
                "Errore durante l'eliminazione dell'associazione tra il software e la macchina"
            );
        }
    }

    public static async search(search: string): Promise<Machine> {
        return await (
            await axios.post(
                ApiHelper.getEndpoint(RouteName.MACHINES) + "/search",
                { search },
                {
                    headers: ApiHelper.headers
                }
            )
        ).data;
    }
}
