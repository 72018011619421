import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import { Language } from '../models/language';
import Reference from '../models/reference';

export default class ReferencesController {
    public static async getAll(): Promise<Reference[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.REFERENCES), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni delle referenze')
        }
    }

    public static async create(reference: Reference): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.REFERENCES), reference, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione della referenza')
        }
    }

    public static async update(reference: Reference): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.REFERENCES), reference, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento della referenza')
        }
    }

    public static async get(id: number): Promise<Reference> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.REFERENCES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni della referenza')
        }
    }

    public static async delete(id: number): Promise<Reference> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.REFERENCES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione della referenza')
        }
    }

    public static async getTranslation(parentId: number, language: Language): Promise<Reference> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.REFERENCES,parentId)+'/translation/'+language, {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download della traduzione della referenza')
        }
    }

    public static async bindMachine(referenceId: number, machineId: number): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.REFERENCES, referenceId) + '/machine', { machineId: machineId }, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'associazione della referenza alla macchina')
        }
    }

    public static async unbindMachine(referenceId: number, machineId: number): Promise<void> {
        try {
            await axios.delete(ApiHelper.getEndpoint(RouteName.REFERENCES, referenceId) + '/machine/' + machineId, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione dell\'associazione tra referenza e la macchina')
        }
    }
}