import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import Machine from '../../models/product/machine';
import Bench from '../../models/bench';
import LanguageManager from '../../backend/LanguageManager';

const MachineBenchesPage: React.FC = () => {
    const [machine, setMachine] = useState<Machine>()
    const [machineBenches, setMachineBenches] = useState<Bench[]>([])
    const {benches, entity} = useSelector((state:State) => state)

    useEffect(() => {
        const getMachineBenches = async () => {
            if(entity && benches){
                const benchesIds: number[] = entity.benches ? entity.benches.map((bench:Bench) => bench.id) : []
                setMachineBenches(benches.filter((bench:Bench) => benchesIds.includes(bench.parent_id ?? bench.id)))
                setMachine(machine)
            }
        }

        getMachineBenches()
        // eslint-disable-next-line
    }, [benches])

    return <EntityPage pageTitle={LanguageManager.get('benches_of_machine', true)+` ${machine ? machine.name : ''}`} entityName='bench' entities={machineBenches} />
};

export default MachineBenchesPage;
