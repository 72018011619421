import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';
import Reference from '../../models/reference';
import ReferencesController from '../../backend/ReferencesController';

const ReferenceViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {references} = useSelector((state: State) => state)

    useEffect(() => {
        const getReference = async () => {
            const currentReference = references && references.find((reference: Reference) => reference.id === +id);

            if(ApiHelper.language !== Language.it && currentReference && currentReference.parent_id){
                try {
                    const parentReference = await ReferencesController.get(currentReference.parent_id);
                    parentReference.name = currentReference.name;
                    parentReference.description = currentReference.description;

                    dispatch({type: SET_ENTITY, entity: parentReference})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentReference});
            }
        }

        getReference()
        // eslint-disable-next-line
    }, [references])

    return <EntityView />
};

export default ReferenceViewPage;
