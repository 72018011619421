import React from 'react'
import { Heading, Box } from 'grommet'
import { FaExclamationTriangle } from 'react-icons/fa'
import styles from './styles'

const NotFound = () => {
    return (
        <Box animation="fadeIn" fill responsive pad="medium">
            <Heading style={styles.header}>
                <FaExclamationTriangle style={styles.icon} />
            Pagina non trovata
            </Heading>
        </Box>
    )
}

export default NotFound
