import React, { useEffect } from 'react'
import { Image, Box, Paragraph } from 'grommet'
import Button from '../../components/button'
import { FaGlobe, FaNewspaper, FaPaperclip } from 'react-icons/fa'
import { useHistory } from 'react-router'
import Grid from '../../components/grid'
import LanguageManager from '../../backend/LanguageManager'
import './style.scss'
import { useDispatch } from 'react-redux'
import { SET_ENTITY_SECTIONS } from '../../store/actions'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const rows = {
    small: ["flex"],
    medium: ["medium","small"],
};

const columns = {
    small: ["flex"],
    medium: ["flex", "flex", "flex", "flex"],
};

interface ISliderData {
    name: string,
    content: string
} 

const sliderData: ISliderData[] = [
    {
        name: 'Mission',
        content: "Sviluppiamo soluzioni innovative ed affidabili in grado di trasformare le idee in prodotti di successo. La nostra dedizione verso il cliente ci impone di fornirgli sempre le soluzioni più efficienti e adatte al contesto competitivo e ci impegniamo a farlo nel tempo in modo scrupoloso, serio e coerente con i nostri valori.",
    },
    {
        name: 'Vision',
        content: "Nell'ultimo secolo la posa in opera di marmi e pietre ha registrato un'espansione mai vista prima nella storia dell'uomo. Tale tendenza in futuro sarà confermata, ma dovrà tener conto di ecosostenibilità e innovazione tecnologia anche attraverso l'impiego di materiali o tecniche alternativi. Crediamo che l'innovazione sia lo strumento per conferire al settore una nuova dimensione industriale capace di coesistere con quella più artigianale.",
    },

    
]

const Welcome = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: []})
        // eslint-disable-next-line
    }, [])

    return (
        <Box direction="column" className="welcome-container" height={{min: '100vh'}}>
            <Box pad="large" margin="50" fill width={{max: '1080px'}} height={{max: '20vh'}} alignSelf="center">
                <Image fit="contain" fill src="/img/logo-landscape.png" />
            </Box>
            <div className="welcome-container-box">
                <div className="welcome-slider">
                    <Slider autoplay autoplaySpeed={5000} infinite dots={false} pauseOnHover arrows={false}>
                        {sliderData.map((data: ISliderData, index: number) => (
                            <Box key={index} pad="small" align="center" height="auto" fill>
                                <div className="slider-element">
                                    <h1>{data.name}</h1>
                                    <Paragraph size="medium" fill>
                                        {data.content}
                                    </Paragraph>
                                </div>
                            </Box>
                        ))}
                    </Slider>
                </div>
                <Box pad="medium" width={{max: '500px'}} margin="auto">
                    <Button onClick={()=>history.push("/app/dashboard")} icon={<Image fill src="/img/sb_logo.svg" />}  />
                </Box>
                <Grid rows={rows} columns={columns} >
                    <Button title={LanguageManager.get('world', true)} onClick={()=>history.push("/app/world")} icon={<FaGlobe />} />
                    <Button title={LanguageManager.get('news', true)} onClick={()=>history.push("/app/news")} icon={<FaNewspaper />}  />
                    <Button title={LanguageManager.get('history', true)} onClick={()=>history.push("/app/history")} icon={<Image fill src="/img/logo-white.svg" />}  />
                    <Button title={LanguageManager.get('generic_documents', true)} onClick={()=>history.push("/app/extra-attachments")} icon={<FaPaperclip />}  />
                </Grid>
            </div>
        </Box>
    )
}

export default Welcome
