import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import Machine from '../../models/product/machine';
import Working from '../../models/working';
import LanguageManager from '../../backend/LanguageManager';

const MachineWorkingsPage: React.FC = () => {
    const [machine, setMachine] = useState<Machine>()
    const [machineWorkings, setMachineWorkings] = useState<Working[]>([])
    const {workings, entity} = useSelector((state:State) => state)

    useEffect(() => {
        const getMachineBenches = async () => {
            if(entity && workings){
                const workingsIds: number[] = entity.workings ? entity.workings.map((working:Working) => working.id) : []
                setMachineWorkings(workings.filter((working:Working) => workingsIds.includes(working.parent_id ?? working.id)))
                setMachine(machine)
            }
        }

        getMachineBenches()
        // eslint-disable-next-line
    }, [workings])

    return <EntityPage pageTitle={LanguageManager.get('workings_of_machine', true)+` ${machine ? machine.name : ''}`} entityName='working' entities={machineWorkings} />
};

export default MachineWorkingsPage;
