import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import Machine from '../../models/product/machine';
import LoadSystem from '../../models/loadSystem';
import LanguageManager from '../../backend/LanguageManager';

const MachineLoadSystemsPage: React.FC = () => {
    const [machine, setMachine] = useState<Machine>()
    const [machineLoadSystems, setMachineLoadSystems] = useState<LoadSystem[]>([])
    const {loadSystems, entity} = useSelector((state:State) => state)

    useEffect(() => {
        const getAll = async () => {
            if(entity && loadSystems){
                const loadIds: number[] = entity.loadSystems ? entity.loadSystems.map((load:LoadSystem) => load.id) : []
                setMachineLoadSystems(loadSystems.filter((load:LoadSystem) => loadIds.includes(load.parent_id ?? load.id)))
                setMachine(machine)
            }
        }

        getAll()
        // eslint-disable-next-line
    }, [loadSystems])

    return <EntityPage pageTitle={LanguageManager.get('loadSystem_of_machine', true)+` ${machine ? machine.name : ''}`} entityName='load-system' entities={machineLoadSystems} />
};

export default MachineLoadSystemsPage;
