import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Bench from '../models/bench';

export default class BenchesController {
    public static async getAll(): Promise<Bench[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.BENCHES), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni dei banchi')
        }
    }

    public static async create(optional: Bench): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.BENCHES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione del banco')
        }
    }

    public static async update(optional: Bench): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.BENCHES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento del banco')
        }
    }

    public static async get(id: number): Promise<Bench> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.BENCHES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni del banco')
        }
    }

    public static async delete(id: number): Promise<Bench> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.BENCHES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione del banco')
        }
    }
}