import React, {  useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { State } from '../store/state';
import Attachment from '../models/attachment/attachment';
import AttachmentsController from '../backend/AttachmentsController';
import PhotoGallery from '../components/photoGallery';

const PhotoGalleryPage = () => {
    const entity = useSelector((state: State) => state.entity)
    const [photos, setPhotos] = useState<Attachment[]>([])

    useEffect(() => {
        const getPhotos = async () => {
            if(entity && entity.photos){
                try {
                    const atts: Attachment[] = [];
                    for (const att of entity.photos) {
                        if(att.id){
                            atts.push(await AttachmentsController.get(att.id))
                        }
                    }

                    setPhotos(atts)
                } catch (error) {
                    alert(error.message)
                }
            }
        }

        getPhotos()
    }, [entity])

    return <PhotoGallery entityName={entity && entity.name} photos={photos} />
}

export default PhotoGalleryPage
