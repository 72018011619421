import React, { useEffect, useState } from 'react'
import { BoxGridElement } from '../boxGrid'
import { Sidebar, Nav, Avatar,  Text, Box } from 'grommet'
import { useHistory } from 'react-router'
import styles from './styles';
import { FaArrowLeft, FaHome, FaBars,  FaSearch } from 'react-icons/fa';
import User from '../../models/user';
import ModalLayer from '../modalLayer';
import './styles.css';
import ContextSidebarButton from '../contextSidebarButton';
import { Dashboard } from '../../pages/Dashboard';
import { useDispatch } from 'react-redux';
import { SET_POI_NAVIGATION } from '../../store/actions';
import LanguageManager from '../../backend/LanguageManager';

interface Props {
    entitySections: BoxGridElement[];
    user?: User | null;
    onAvatarClick?: ()=>void;
    onSearchClick?: ()=>void;
    poiNavigation?: boolean;
    firstPage?: boolean;
}

const ContextSidebar = (props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [showMobile, setShowMobile] = useState<boolean>(false)

    useEffect(() => {
        dispatch({type: SET_POI_NAVIGATION, poiNavigation: props.poiNavigation})
        // eslint-disable-next-line
    }, [props.poiNavigation])

    const closeBurgerMenu = () => {
        const overlay:any = document.querySelector('.bm-overlay');
        if(overlay){
            overlay.click();
        }
    }

    return (
        <div>
            <div className="sidebar-menu-desktop">
                <Sidebar background="donatoni_1" style={styles.container} pad="none"
                    header={
                        !props.poiNavigation ? 
                            <Avatar onClick={props.onAvatarClick} background="dark-1" style={styles.avatar}>{props.user ? props.user.firstName.substr(0,1).toUpperCase() : ''}</Avatar> : 
                            <div style={styles.avatar} />
                    }
                    footer={
                        <Text color="donatoni_white" size="small" style={styles.version}>v.{process.env.REACT_APP_VERSION}</Text>
                    } >
                    <Nav gap="small">
                        {!props.poiNavigation && <ContextSidebarButton title={LanguageManager.get('home', true)} icon={<FaHome  />} onClick={()=>history.push('/app')} />}
                        {!props.firstPage && <ContextSidebarButton title={LanguageManager.get('back', true)} icon={<FaArrowLeft />} onClick={() => history.goBack()} />}
                        {!props.poiNavigation && <ContextSidebarButton title={LanguageManager.get('search', true)} icon={<FaSearch />} onClick={() => props.onSearchClick && props.onSearchClick()} />}
                        {!props.poiNavigation && <ContextSidebarButton title="Salesbook" icon={<FaBars />} onClick={() => setShowMenu(true)} />}
                        {props.entitySections && props.entitySections.map((element: BoxGridElement, index: number) => (
                            <ContextSidebarButton key={index} title={element.title} icon={element.icon} onClick={() => element.link && history.push(element.link)} />
                        ))}                        
                    </Nav>
                </Sidebar>
            </div>
            <div className="sidebar-menu-mobile">
                <Box direction="row" justify="between" align="center" style={styles.mobileBar}>
                    <Box align="start" pad="small">
                        <ContextSidebarButton icon={<FaBars />} onClick={()=>setShowMobile(true)} title="Menu" />
                    </Box>
                    {!props.poiNavigation ? <Avatar onClick={props.onAvatarClick} background="dark-1" style={styles.avatar}>{props.user ? props.user.firstName.substr(0,1).toUpperCase() : ''}</Avatar>
                        : <div style={styles.avatar} />}
                </Box>
            </div>
            {showMenu && (
                <ModalLayer onClose={() => setShowMenu(false)} closeOnCapture title="Salesbook menu" >
                    <Dashboard />
                </ModalLayer>
            )}
            {showMobile && (
                <ModalLayer onClose={() => setShowMobile(false)} closeOnCapture title="Menu" slide >
                    <Sidebar  
                        footer={
                            <Text color="donatoni_black" size="small" style={styles.version}>v.{process.env.REACT_APP_VERSION}</Text>
                        } 
                        style={styles.mobileContainer}>
                        <Nav gap="small" onClick={closeBurgerMenu}>
                            {!props.poiNavigation && <ContextSidebarButton title={LanguageManager.get('home', true)} icon={<FaHome />} onClick={() => history.push('/app')} showLabel />}
                            <ContextSidebarButton title={LanguageManager.get('back', true)} icon={<FaArrowLeft />} onClick={() => history.goBack()} showLabel />
                            {!props.poiNavigation && <ContextSidebarButton title={LanguageManager.get('search', true)} icon={<FaSearch />} onClick={() => props.onSearchClick && props.onSearchClick()} showLabel />}
                            {!props.poiNavigation && <ContextSidebarButton title="Salesbook" icon={<FaBars />} onClick={() => setShowMenu(true)} showLabel />}
                            {props.entitySections && props.entitySections.map((element: BoxGridElement, index: number) => (
                                <ContextSidebarButton key={index} title={element.title} icon={element.icon} onClick={() => element.link && history.push(element.link)} showLabel />
                            ))}
                        </Nav>
                    </Sidebar>
                </ModalLayer>
            )}
        </div>
    )
}

export default ContextSidebar
