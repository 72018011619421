import React, { ReactNode } from 'react'
import { Box,  Paragraph, Image } from 'grommet'
import styles from './styles'
import AttachmentsController from '../../backend/AttachmentsController'

interface Props {
    title?: string,
    content?: ReactNode,
    onClick?: ()=>void;
    icon?: ReactNode,
    imageId?: number,
    background?: string;
}

const Button = (props: Props) => {
    return (
        <Box style={styles.box} round="small" background={props.background ? props.background : "donatoni_1"} onClick={props.onClick}>
            {props.content ? (
                <Box alignSelf="stretch">
                    {props.content}
                </Box>
            ) : (
                <Box animation="fadeIn">
                    {props.imageId ? (
                        <Image loading='eager' src={AttachmentsController.getContentLink(props.imageId)} style={styles.photo}/>
                    ) : props.icon ? (
                        <Box pad="small" style={styles.icon}>
                            {props.icon}
                        </Box>
                    ) : ''}
                </Box>
            )}
            {props.title && <Paragraph size="xxlarge" margin={{start: '5px', end: '5px'}} fill>{props.title}</Paragraph>}
        </Box>
    )
}

export default Button
