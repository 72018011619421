import React, { useRef, useState } from 'react'
import { Box, Button, Drop } from 'grommet'
import {isMobile} from 'react-device-detect';
import styles from './styles';
interface Props {
    title?: string;
    showLabel?: boolean;
    icon: any;
    onClick: ()=>void;
}

const ContextSidebarButton = (props: Props) => {
    const ref: any = useRef();
    const [over, setOver] = useState(false);

    return (
        <Box fill="horizontal" align="start">
            <Button 
                ref={ref}
                onMouseOver={() => setOver(true)}
                onMouseLeave={() => setOver(false)}
                onFocus={() => setOver(false)}
                onBlur={() => setOver(false)}
                style={styles.button} 
                icon={props.icon} 
                onClick={props.onClick} 
                label={props.showLabel && props.title}
                hoverIndicator
                plain />
            {!isMobile && !props.showLabel && ref.current && over && (
                <Drop align={{ left: 'right' }} target={ref.current} plain>
                    <Box
                        animation="slideRight"
                        pad="small"
                        background='donatoni_1'
                        round={{ size: 'medium', corner: 'right' }}
                    >
                        {props.title}
                    </Box>
                </Drop>
            )}
        </Box>
    )
}

export default ContextSidebarButton
