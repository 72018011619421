import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Working from '../../models/working';
import Machine from '../../models/product/machine';
import WorkingsController from '../../backend/WorkingsController';
import LanguageManager from '../../backend/LanguageManager';

const WorkingMachinesPage: React.FC = () => {
    const {id} = useParams();
    const [working, setWorking] = useState<Working>()
    const [workingMachines, setWorkingMachines] = useState<Machine[]>([])
    const {machines} = useSelector((state:State) => state)

    const get = async () => {
        try {
            return await WorkingsController.get(id);
        } catch (error) {
            toast.warn(error.message)
        }
    }

    useEffect(() => {
        const getMachineOptionals = async () => {
            const working = await get()
            if(working && machines){
                const machinesIds: number[] = working.machines ? working.machines.map((machine:Machine) => machine.id) : []
                setWorkingMachines(machines.filter(machine => machinesIds.includes(machine.id)))
                setWorking(working)
            }
        }

        getMachineOptionals()
        // eslint-disable-next-line
    }, [machines])

    return <EntityPage pageTitle={LanguageManager.get('machines_of_workings', true)+` ${working ? working.name : ''}`} entityName='machine' entities={workingMachines} />
};

export default WorkingMachinesPage;
