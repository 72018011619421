import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Notification from '../models/notification'

export default class NotificationsController {
    public static async getAll(): Promise<Notification[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.NOTIFICATIONS), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle notifiche')
        }
    }

    public static async updateUserNotificationDate(notification: Notification): Promise<void> {
        try {
            return (await axios.put(ApiHelper.getEndpoint(RouteName.NOTIFICATIONS), {
                lastNotifyDate: notification.createdAt
            }, {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento della data di notifica dell\'utente')
        }
    }
}