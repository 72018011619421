import {
    SceneLoader,
    Scene,
    ActionManager,
    AbstractMesh,
    ExecuteCodeAction,
    HighlightLayer,
    Color3,
    Database,
    Mesh
} from "@babylonjs/core";
import "babylonjs-loaders";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Button } from "@babylonjs/gui/2D/controls/button";
import Poi from "../models/poi";
import { GLTFFileLoader } from "babylonjs-loaders";
import { Control, ScrollViewer, StackPanel } from "@babylonjs/gui/2D/controls";

export default class Model {
    private scene: Scene;
    private highlightLayer: HighlightLayer;
    private advancedTexture: any;
    private pois: Poi[];
    private fileUrl: string;
    private buttons: Button[];
    private poiPanel: ScrollViewer | null;
    //private shadowGenerator?: ShadowGenerator;
    public meshSelectionCallback: ((meshName: string) => void) | undefined;
    public buttonClickCallback:
        | ((
              poiClicked: number | null,
              mesh: AbstractMesh | null,
              button: Button | null
          ) => void)
        | undefined;

    /**
     * Init the model
     * @param scene The scene of the playground
     * @param file A string created using createObjectURL function
     * @param meshSelectionCallback A callback used to trigger an action when the user clicks on a model surface
     * @param pois An array of pois to bind to meshes
     */
    constructor(scene: Scene, fileUrl: string, pois: Poi[] = []) {
        this.scene = scene;
        this.highlightLayer = new HighlightLayer("hl1", this.scene);
        this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.pois = pois;
        this.fileUrl = fileUrl;
        this.buttons = [];
        GLTFFileLoader.IncrementalLoading = false;
        Database.IDBStorageEnabled = false;
        this.initToolBar();
        this.poiPanel = null;
    }

    public load() {
        SceneLoader.Append(
            "",
            this.fileUrl,
            this.scene,
            (container) => {
                const meshes = container.meshes;
                this.scene.blockfreeActiveMeshesAndRenderingGroups = true;

                this.resetCamera();

                this.initStackPanel(meshes);
                this.setHighlightMeshes(meshes);

                this.scene.blockfreeActiveMeshesAndRenderingGroups = false;
            },
            undefined,
            undefined,
            ".gltf"
        );
    }

    private resetCamera() {
        const camera: any = this.scene.cameras[0];
        this.highlightLayer.dispose();
        camera.lowerRadiusLimit = null;
        const worldExtends = this.scene.getWorldExtends(function (mesh) {
            return mesh.isVisible && mesh.isEnabled();
        });
        camera.framingBehavior.zoomOnBoundingInfo(
            worldExtends.min,
            worldExtends.max
        );
        camera.beta = Math.PI / 3;
        camera.alpha = Math.PI - Math.PI / 2;

        this.unselectAllButtons();
    }

    private setHighlightMeshes(meshes: AbstractMesh[]) {
        meshes.forEach((mesh: any) => {
            const poi = this.getPoi(mesh.name);
            if (!poi) {
                return;
            }
            mesh.actionManager = new ActionManager(this.scene);
            mesh.actionManager.registerAction(
                new ExecuteCodeAction(ActionManager.OnPickTrigger, (event) => {
                    const mesh: any = event.meshUnderPointer;
                    this.selectMesh(mesh);
                    this.selectButton(poi.id, mesh);

                    if (this.meshSelectionCallback) {
                        this.meshSelectionCallback(mesh.name);
                    }
                })
            );
        });
    }

    private getPoi(meshName: string): Poi | null {
        for (const poi of this.pois) {
            if (poi.machines[0].refElementName === meshName) {
                return poi;
            }
        }

        return null;
    }

    private createHighlightLayer() {
        this.highlightLayer = new HighlightLayer("hl1", this.scene);
    }

    private initStackPanel(meshes: AbstractMesh[]) {
        this.poiPanel = new ScrollViewer();
        this.poiPanel.width = "302px";
        this.poiPanel.height = "100%";
        this.poiPanel.paddingTop = "70px";
        this.poiPanel.paddingBottom = "70px";
        this.poiPanel.background = "#212121";
        this.poiPanel.color = "#212121";
        this.poiPanel.thickness = 5;
        this.poiPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        this.poiPanel.isVisible = false;

        this.advancedTexture.addControl(this.poiPanel);

        const panel = new StackPanel();
        panel.width = "100%";

        this.poiPanel.addControl(panel);

        for (const poi of this.pois) {
            const button = Button.CreateSimpleButton(
                poi.id.toString(),
                poi.title
            );
            // button.width = 0.2;
            button.height = "45px";
            button.color = "white";
            button.thickness = 0.1;
            button.background = "#212121";
            panel.addControl(button);

            const mesh: any = meshes.find(
                (mesh: any) => mesh.name === poi.machines[0].refElementName
            );

            if (mesh) {
                button.onPointerUpObservable.add(() => {
                    this.selectMesh(mesh);
                    this.selectButton(poi.id, mesh);
                });
            }

            this.buttons.push(button);
        }
    }

    private initToolBar() {
        const panel = new StackPanel();
        panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
        panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        panel.isVertical = false;
        panel.top = "-20px";
        panel.left = "-20px";
        panel.width = "90px";
        panel.height = "40px";

        this.advancedTexture.addControl(panel);

        const resetButton = Button.CreateImageOnlyButton(
            "resetView",
            "/img/refresh.png"
        );
        resetButton.width = "40px";
        resetButton.height = "40px";
        resetButton.thickness = 0;

        const POIButton = Button.CreateImageOnlyButton(
            "resetView",
            "/img/poi.png"
        );
        POIButton.width = "50px";
        POIButton.height = "40px";
        POIButton.thickness = 0;
        POIButton.paddingLeft = "10px";

        resetButton.onPointerUpObservable.add(() => this.resetCamera());
        POIButton.onPointerUpObservable.add(() => this.togglePoiPanel());

        panel.addControl(resetButton);
        panel.addControl(POIButton);
    }

    private selectButton(poiId: number, mesh: Mesh) {
        for (const button of this.buttons) {
            if (button.name === poiId.toString()) {
                button.background = "#005AA5";
                this.buttonClickCallback &&
                    this.buttonClickCallback(poiId, mesh, button);
            } else {
                button.background = "#212121";
            }
        }
    }

    private unselectAllButtons() {
        for (const button of this.buttons) {
            button.background = "#212121";
        }

        this.buttonClickCallback && this.buttonClickCallback(null, null, null);
    }

    private selectMesh(mesh: Mesh) {
        this.highlightLayer && this.highlightLayer.dispose();
        this.createHighlightLayer();
        this.highlightLayer.addMesh(mesh, Color3.Green());

        if (this.scene.activeCamera) {
            this.selectAndZoom(mesh);
        }
    }

    private togglePoiPanel() {
        if (this.poiPanel) {
            this.poiPanel.isVisible = !this.poiPanel.isVisible;
        }
    }

    private selectAndZoom(mesh: Mesh) {
        const camera: any = this.scene.activeCamera;
        camera.setTarget(mesh);
        //camera.framingBehavior.zoomOnMesh(mesh);
    }
}
