import React, { useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { SET_ENTITY_SECTIONS } from '../../store/actions';
import LanguageManager from '../../backend/LanguageManager';

const OptionalPage: React.FC = props => {
    const optionals = useSelector((state:State) => state.optionals)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: []})
        // eslint-disable-next-line
    }, [])
    
    return <EntityPage pageTitle={LanguageManager.get('optionals', true)} entityName='optional' entities={optionals} />
};

export default OptionalPage;
