import React, { useState, useEffect } from 'react';
import News from '../../components/news';
import NotificationsController from '../../backend/notificationsController';
import Notification, { Action } from '../../models/notification';
import Grid from '../../components/grid';
import { FaTimes, FaEdit, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router';

const rows = {
    small: ["flex"],
};

const columns = {
    small: ["flex"],
    medium: ["flex", "flex"],
    large: ["flex","flex","flex","flex"],
};

const NewsPage: React.FC = () => {
    const [news, setNews] = useState<Notification[]>();
    const history = useHistory();
    
    useEffect(() => {
        const getNews = async () => {
            try {
                setNews(await NotificationsController.getAll())
            } catch (error) {
                alert(error.message)
            }
        }

        getNews()
    }, [])

    const getTitle = (notification: Notification) => {
        switch(+notification.action){
        case Action.created:
            return <FaPlus />
        case Action.updated:
            return <FaEdit />
        case Action.deleted:
            return <FaTimes />
        default:
            return '';
        }
    }

    const handleClick = (news: Notification) => {
        history.push(`/app/${news.entity}/${news.entity_id}`)
    }

    return (
        <Grid rows={rows} columns={columns}>
            {news && news.map((notification: Notification, index: number) => (
                <News key={index} icon={getTitle(notification)} news={notification} onClick={handleClick} />
            ))}
        </Grid>
    );
};

export default NewsPage;
