import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        alignSelf: 'center',
        fontSize: '12em',
        opacity: '70%',
        /* filter: 'brightness(500%)', */
    },
    photo: {
        // filter: 'grayscale(100%)',
        overflow: 'hidden',
        width: '100%',
        height: '300px',
        objectFit: 'cover',
        opacity: '60%',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    }
}

export default styles;