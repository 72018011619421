import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import Machine from '../../models/product/machine';
import Software from '../../models/product/software';
import LanguageManager from '../../backend/LanguageManager';

const MachineSoftwarePage: React.FC = () => {
    const [machine, setMachine] = useState<Machine>()
    const [machineSoftwares, setMachineSoftwares] = useState<Software[]>([])
    const {softwares, entity} = useSelector((state:State) => state)

    useEffect(() => {
        const getMachineSoftwares = async () => {
            if(entity && softwares){
                const softwareIds: number[] = entity.softwares ? entity.softwares.map((software:Software) => software.id) : []
                setMachineSoftwares(softwares.filter(software => softwareIds.includes(software.parent_id ?? software.id)))
                setMachine(machine)
            }
        }

        getMachineSoftwares()
        // eslint-disable-next-line
    }, [softwares])

    return <EntityPage pageTitle={LanguageManager.get('softwares_of_machine', true)+` ${machine ? machine.name : ''}`} entityName='software' entities={machineSoftwares} />
};

export default MachineSoftwarePage;
