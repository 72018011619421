import React from 'react'
import { Box, Heading } from 'grommet'
import BounceLoader from "react-spinners/BounceLoader";
import styles from './styles';

interface Props {
    title?: string
}

const Loading = (props: Props) => {
    return (
        <Box fill responsive style={styles.container} pad="medium">
            <Heading level="2" color="donatoni_1" style={styles.title}>{props.title}</Heading>
            <BounceLoader size="150px" loading color="#005AA5" />
        </Box>
    )
}

export default Loading
