import React, { useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { SET_ENTITY_SECTIONS } from '../../store/actions';
import LanguageManager from '../../backend/LanguageManager';

const LoadSystemsPage: React.FC = props => {
    const loadSystems = useSelector((state: State) => state.loadSystems)   

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: []})
        // eslint-disable-next-line
    }, [])
    
    return <EntityPage entityName="load-system" pageTitle={LanguageManager.get('load_systems', true)} entities={loadSystems} />
};

export default LoadSystemsPage;
