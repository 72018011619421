import React from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import LanguageManager from '../../backend/LanguageManager';

const WorkingsPage: React.FC = props => {
    const workings = useSelector((state: State) => state.workings)   

    return <EntityPage entityName="working" pageTitle={LanguageManager.get('workings', true)} entities={workings} />
};

export default WorkingsPage;
