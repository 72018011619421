import React, { useEffect } from 'react';
import { useParams} from 'react-router';
import Software from '../../models/product/software';
import { State } from '../../store/state';
import { useSelector, useDispatch } from 'react-redux'
import EntityView from '../../components/entityView';
import SoftwaresController from '../../backend/SoftwaresController';
import ApiHelper from '../../backend/apiHelper';
import { Language } from '../../models/language';
import { SET_ENTITY } from '../../store/actions';
import { toast } from 'react-toastify';


const SoftwareViewPage: React.FC = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const {softwares} = useSelector((state: State) => state)

    useEffect(() => {
        const getSoftware = async () => {
            const currentSoftware = softwares && softwares.find((software: Software) => software.id === +id);

            if(ApiHelper.language !== Language.it && currentSoftware && currentSoftware.parent_id){
                try {
                    const parentMachine = await SoftwaresController.get(currentSoftware.parent_id);
                    parentMachine.name = currentSoftware.name;
                    parentMachine.description = currentSoftware.description;

                    dispatch({type: SET_ENTITY, entity: parentMachine})
                } catch (error) {
                    toast.error(error.message)
                }
            } else {
                dispatch({type: SET_ENTITY, entity: currentSoftware});
            }
        }

        getSoftware()
        // eslint-disable-next-line
    }, [softwares])

    return <EntityView />
};
export default SoftwareViewPage;
