import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Machine from '../../models/product/machine';
import Reference from '../../models/reference';
import ReferencesController from '../../backend/ReferencesController';
import LanguageManager from '../../backend/LanguageManager';

const ReferenceMachinesPage: React.FC = () => {
    const {id} = useParams();
    const [reference, setReference] = useState<Reference>()
    const [referenceMachines, setReferenceMachines] = useState<Machine[]>([])
    const {machines} = useSelector((state:State) => state)

    const get = async () => {
        try {
            return await ReferencesController.get(id);
        } catch (error) {
            toast.warn(error.message)
        }
    }

    useEffect(() => {
        const getMachineOptionals = async () => {
            const reference = await get()
            if(reference && machines){
                const machinesIds: number[] = reference.machines ? reference.machines.map((machine:Machine) => machine.id) : []
                setReferenceMachines(machines.filter(machine => machinesIds.includes(machine.id)))
                setReference(reference)
            }
        }

        getMachineOptionals()
        // eslint-disable-next-line
    }, [machines])

    return <EntityPage pageTitle={LanguageManager.get('machines_of_reference', true)+` ${reference ? reference.name : ''}`} entityName='machine' entities={referenceMachines} />
};

export default ReferenceMachinesPage;
