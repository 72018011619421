import React from 'react';
import {FaDesktop, FaPencilRuler, FaBuffer, FaAward, FaVectorSquare, FaTruckLoading} from 'react-icons/fa'
import Button from '../components/button';
import { useHistory } from 'react-router';
import { MdSettingsInputComponent, MdGroupWork, MdDashboard } from 'react-icons/md';
import Grid from '../components/grid';
import LanguageManager from '../backend/LanguageManager';

const rows = {
    small: ["auto"],
    medium: ["flex","flex","flex"],
};

const columns = {
    small: ["flex"],
    medium: ["flex"],
    large: ["flex", "flex", "flex"],
};
    
export const Dashboard: React.FC = () => {
    const history = useHistory();

    return (
        <div>
            <Grid rows={rows} columns={columns}>
                <Button title={LanguageManager.get('machines', true)} onClick={()=>history.push('/app/machines')} icon={<MdGroupWork />} />
                <Button title={LanguageManager.get('optionals', true)} onClick={()=>history.push('/app/optionals')} icon={<MdSettingsInputComponent />} />
                <Button title={LanguageManager.get('softwares', true)} onClick={()=>history.push('/app/softwares')} icon={<FaDesktop />} />
                <Button title={LanguageManager.get('software_modules', true)} onClick={()=>history.push('/app/software-modules')} icon={<MdDashboard />} />
                <Button title={LanguageManager.get('benches', true)} onClick={()=>history.push('/app/benches')} icon={<FaVectorSquare />} />
                <Button title={LanguageManager.get('load_systems', true)} onClick={()=>history.push('/app/load-systems')} icon={<FaTruckLoading />} />
                <Button title={LanguageManager.get('workings', true)} onClick={()=>history.push('/app/workings')} icon={<FaPencilRuler />} />
                <Button title={LanguageManager.get('services', true)} onClick={()=>history.push('/app/services')} icon={<FaBuffer />} />
                <Button title={LanguageManager.get('references', true)} onClick={()=>history.push('/app/references')} icon={<FaAward />} />
            </Grid>
        </div>
    )
};
