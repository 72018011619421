import React, { useState, useEffect } from 'react';
import { State } from '../../store/state';
import { useSelector } from 'react-redux'
import EntityPage from '../../components/entityPage';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import Machine from '../../models/product/machine';
import Optional from '../../models/optional/optional';
import OptionalsController from '../../backend/OptionalsController';
import LanguageManager from '../../backend/LanguageManager';

const OptionalMachinesPage: React.FC = () => {
    const {id} = useParams();
    const [optional, setOptional] = useState<Optional>()
    const [optionalMachines, setOptionalMachines] = useState<Machine[]>([])
    const {machines} = useSelector((state:State) => state)

    const get = async () => {
        try {
            return await OptionalsController.get(id);
        } catch (error) {
            toast.warn(error.message)
        }
    }

    useEffect(() => {
        const getMachineOptionals = async () => {
            const optional = await get()
            if(optional && machines){
                const machinesIds: number[] = optional.machines ? optional.machines.map((machine:Machine) => machine.id) : []
                setOptionalMachines(machines.filter(machine => machinesIds.includes(machine.id)))
                setOptional(optional)
            }
        }

        getMachineOptionals()
        // eslint-disable-next-line
    }, [machines])

    return <EntityPage pageTitle={LanguageManager.get('machines_of_optional', true)+` ${optional ? optional.name : ''}`} entityName='machine' entities={optionalMachines} />
};

export default OptionalMachinesPage;
