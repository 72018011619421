import { Language } from "../models/language";

export enum RouteName {
    LOGIN = "login",
    MACHINES = "machines",
    OPTIONALS = "optionals",
    BENCHES = "benches",
    LOAD_SYSTEMS = "load-systems",
    SOFTWARES = "softwares",
    SOFTWARE_MODULES = "software-modules",
    ATTACHMENTS = "attachments",
    POIS = "pois",
    USERS = "users",
    NOTIFICATIONS = "notifications",
    STATS = "stats",
    WORKINGS = "workings",
    SERVICES = "services",
    REFERENCES = "references",
    EXTRA_ENTITY = "extra-entities",
    WORLDZONES = "worldzones"
}

export default class ApiHelper {
    private static LANGUAGE_KEY = "salesbook_lang";

    public static get headers(): Record<string, any> {
        const token: string | null = this.token;

        return {
            Accept: "application/json",
            "Content-Type": "application/json",
            "fox-token": token ? token : "",
            language: this.language,
            draft: false
        };
    }

    public static get language() {
        const language = localStorage.getItem(this.LANGUAGE_KEY);

        if (language) {
            return +language;
        } else {
            return Language.it;
        }
    }

    public static set language(lang: Language) {
        localStorage.setItem(this.LANGUAGE_KEY, lang.toString());
    }

    public static get endpoint(): string {
        if (
            process.env.REACT_APP_BACKEND_ENDPOINT &&
            process.env.REACT_APP_BACKEND_PORT
        ) {
            return `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_HOSTNAME}:${process.env.REACT_APP_BACKEND_PORT}${process.env.REACT_APP_BACKEND_ENDPOINT}`;
        } else {
            throw new Error("Cannot generate endpoint!");
        }
    }

    public static setLocalToken(token: string) {
        if (process.env.REACT_APP_TOKEN_NAME) {
            localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
        } else {
            throw new Error("Cannot set the token!");
        }
    }

    public static clearToken() {
        if (process.env.REACT_APP_TOKEN_NAME) {
            localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        }
    }

    public static get token() {
        if (process.env.REACT_APP_TOKEN_NAME) {
            return localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
        } else {
            throw new Error("Token environment variable is not defined!");
        }
    }

    public static get verifyEndpoint() {
        return ApiHelper.endpoint + RouteName.LOGIN + "/verify";
    }

    public static get loginEndpoint() {
        return ApiHelper.endpoint + RouteName.LOGIN;
    }

    public static getEndpoint(route: RouteName, id: number | null = null) {
        let endpoint = ApiHelper.endpoint + route;
        if (id) {
            endpoint += "/" + id;
        }

        return endpoint;
    }
}
