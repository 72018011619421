import React from 'react'
import { Box, Heading, Image, Paragraph } from 'grommet'
import VideoShow from '../../components/videoShow'
import Button from '../../components/button'
import { FaDownload } from 'react-icons/fa'

const HistoryPage = () => {
    return (
        <Box pad="medium" align="center" >
            <Heading level="1">Una storia di Successo</Heading>
            <Box direction="row" wrap align="center">
                <Box>
                    <Image src="/img/logo.png" fit="contain" />
                </Box>
                <Box>
                    <Paragraph margin="medium">
                        Fondata nel 1959, Donatoni Macchine vanta ormai oltre 60 anni di esperienza in questo settore, diventando leader e punto di riferimento nella produzione di tecnologie all&apos;avanguardia per la lavorazione del marmo e della pietra naturale.
                    </Paragraph>
                    <Paragraph margin="medium">
                        I suoi prodotti, l&apos;affidabilità e la competenza del suo personale, lo stretto rapporto costruito con i suoi clienti e la sua assistenza specializzata hanno fatto di Donatoni un marchio riconosciuto, sinonimo di qualità, servizio e innovazione; e le 1500 o più macchine installate in tutto il mondo sono il risultato di un impegno incessante nel corso degli anni che continua ancora oggi con l&apos;ultima generazione.
                    </Paragraph>
                </Box>
            </Box>
            <Box pad="large" width="100%">
                <VideoShow videoId="uvFo4FDWD48" hideTitle />
            </Box>
            <Box pad="large" width="100%">
                <Button icon={<FaDownload />} title="Scarica il company profile" onClick={()=>window.open('/files/Company_Profile.pdf')} />
            </Box>
        </Box>
    )
}

export default HistoryPage
