import axios from 'axios';
import ApiHelper, { RouteName } from './apiHelper';
import Service from '../models/service';

export default class ServiceController {
    public static async getAll(): Promise<Service[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.SERVICES), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni dei servizi')
        }
    }

    public static async create(optional: Service): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.SERVICES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione del servizio')
        }
    }

    public static async update(optional: Service): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.SERVICES), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento del servizio')
        }
    }

    public static async get(id: number): Promise<Service> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.SERVICES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni del servizio')
        }
    }

    public static async delete(id: number): Promise<Service> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.SERVICES, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione del servizio')
        }
    }
}