import axios from 'axios';
import LoadSystem from '../models/loadSystem';
import ApiHelper, { RouteName } from './apiHelper';

export default class LoadSystemsContoller {
    public static async getAll(): Promise<LoadSystem[]> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.LOAD_SYSTEMS), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            console.log(error.message)
            throw new Error('Errore durante il download delle informazioni dei sistemi di carico/scarico')
        }
    }

    public static async create(optional: LoadSystem): Promise<void> {
        try {
            await axios.post(ApiHelper.getEndpoint(RouteName.LOAD_SYSTEMS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante la creazione del sistema di carico/scarico')
        }
    }

    public static async update(optional: LoadSystem): Promise<void> {
        try {
            await axios.put(ApiHelper.getEndpoint(RouteName.LOAD_SYSTEMS), optional, {
                headers: ApiHelper.headers
            })
        } catch (error) {
            throw new Error('Errore durante l\'aggiornamento del sistema di carico/scarico')
        }
    }

    public static async get(id: number): Promise<LoadSystem> {
        try {
            return (await axios.get(ApiHelper.getEndpoint(RouteName.LOAD_SYSTEMS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante il download delle informazioni del sistema di carico/scarico')
        }
    }

    public static async delete(id: number): Promise<LoadSystem> {
        try {
            return (await axios.delete(ApiHelper.getEndpoint(RouteName.LOAD_SYSTEMS, id), {
                headers: ApiHelper.headers
            })).data
        } catch (error) {
            throw new Error('Errore durante l\'eliminazione del sistema di carico/scarico')
        }
    }
}