import React from 'react'
import Attachment from '../../models/attachment/attachment'
import { Button, Box } from 'grommet'
import { FaDownload } from 'react-icons/fa'
import './style.scss'
import AttachmentsController from '../../backend/AttachmentsController'

interface Props {
    attachment: Attachment
}

const DownloadButton = (props: Props) => {
    const handleClick = () => window.open(AttachmentsController.getContentLink(props.attachment.id));

    return (
        <Button onClick={handleClick}>
            <Box align="center">
                <FaDownload className="download-icon" />
            </Box>
        </Button>
    )
}

export default DownloadButton
