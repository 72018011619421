import React from 'react';
import {Button, Form, FormField, Box} from 'grommet';
import './login.css';

interface Props {
    onSubmit: (value: any) => void;
}

const LoginForm: React.FC<Props> = (props: Props) => {
    return (
        <Box direction="column" pad="medium" className="login" align="center" round="small" elevation="large">
            <img src="img/logo-landscape.png" className="logo" alt="logo" />
            <img src="img/sb_logo.png" className="logo" alt="logo" />
            <Form onSubmit={props.onSubmit} style={{paddingTop: '20px', textAlign: 'center'}}>
                <FormField name="username" label="Username" required />
                <FormField name="password" label="Password" type="password" required />
                <Button className="submit" type="submit" primary color="donatoni_1" label="Login" />
            </Form>
        </Box>
    );
};

export default LoginForm;
