import React, { useEffect, useState } from 'react'
import { Box, Paragraph, Image} from 'grommet'
import parse from 'html-react-parser';
import { FaCube, FaImage, FaVideo, FaFile, FaVectorSquare, FaAward, FaPencilRuler, FaFileAlt, FaHashtag, FaTruckLoading } from 'react-icons/fa'
import { MdSettingsInputComponent, MdDesktopMac, MdDashboard, MdGroupWork } from 'react-icons/md'
import { BoxGridElement } from '../boxGrid';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../store/state';
import { useHistory } from 'react-router';
import LanguageManager from '../../backend/LanguageManager';
import AttachmentsController from '../../backend/AttachmentsController';
import Attachment from '../../models/attachment/attachment';
import { SET_ENTITY_SECTIONS } from '../../store/actions';
import ContextPage from '../contextPage';

const EntityView = () => {
    const history = useHistory();
    const entity = useSelector((state: State) => state.entity)
    const [coverPhoto, setCoverPhoto] = useState<Attachment | undefined>()
    const dispatch = useDispatch();

    useEffect(() => {
        const elements: BoxGridElement[] = [];

        elements.push(
            {
                title: LanguageManager.get('description', true),
                icon: <FaFileAlt />,
                link: `${history.location.pathname}`,
            }
        )

        if(entity && entity.model){
            elements.push(
                {
                    title: '3D',
                    icon: <FaCube />,
                    link: `${history.location.pathname}/3d`,
                }
            )
        }
            
        if(entity && entity.documents.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('documents', true),
                    icon: <FaFile />,
                    link: '/app/documents',
                }
            )
        }
                
        if(entity && entity.photos.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('photos', true),
                    icon: <FaImage />,
                    link: `/app/photogallery`,
                }
            )
            setCoverPhoto(entity.photos.find((photo: Attachment) => photo.isCover))
        }
                    
        if(entity && entity.videos && JSON.parse(entity.videos).length > 0){
            elements.push(
                {
                    title: LanguageManager.get('videos', true),
                    icon: <FaVideo />,
                    link: '/app/videogallery',
                }
            )
        }
                        
        if(entity && entity.optionals && entity.optionals.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('optionals', true),
                    icon: <MdSettingsInputComponent />,
                    link: `${history.location.pathname}/optionals`,
                }
            )
        }
                            
        if(entity && entity.softwares && entity.softwares.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('softwares', true),
                    icon: <MdDesktopMac />,
                    link: `${history.location.pathname}/softwares`,
                }
            )
        }
                                
        if(entity && entity.modules && entity.modules.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('software_modules', true),
                    icon: <MdDashboard />,
                    link: `${history.location.pathname}/modules`,
                }
            )
        }
                                    
        if(entity && entity.benches && entity.benches.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('benches', true),
                    icon: <FaVectorSquare />,
                    link: `${history.location.pathname}/benches`,
                }
            )
        }

        if(entity && entity.loadSystems && entity.loadSystems.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('load_systems', true),
                    icon: <FaTruckLoading />,
                    link: `${history.location.pathname}/load-systems`,
                }
            )
        }
        
        if(entity && entity.workings && entity.workings.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('workings', true),
                    icon: <FaPencilRuler />,
                    link: `${history.location.pathname}/workings`,
                }
            )
        }
            
        if(entity && entity.references && entity.references.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('references',true),
                    icon: <FaAward />,
                    link: `${history.location.pathname}/references`,
                }
            )
        }
                
        if(entity && entity.machines && entity.machines.length > 0){
            elements.push(
                {
                    title: LanguageManager.get('machines', true),
                    icon: <MdGroupWork />,
                    link: `${history.location.pathname}/machines`,
                }
            )
        }

        if(entity && entity.tags && JSON.parse(entity.tags).length > 0){
            elements.push(
                {
                    title: LanguageManager.get('tags', true),
                    icon: <FaHashtag />,
                    link: `/app/related`,
                }
            )
        }
                    
        dispatch({type: SET_ENTITY_SECTIONS, entitySections: elements})
        // eslint-disable-next-line
    }, [entity])

    return (
        <ContextPage entity={entity}>
            <Box pad="small" responsive fill>
                {coverPhoto && <Box pad="large" margin="50" fill width={{max: '1080px'}} alignSelf="center" animation="fadeIn">
                    <Image fit="contain" fill src={AttachmentsController.getContentLink(coverPhoto.id)} />
                </Box>}
                <Paragraph size="large" fill>
                    {entity && entity.description && parse(entity.description)}
                </Paragraph>
            </Box>
        </ContextPage>
    )
}

export default EntityView
