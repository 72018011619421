import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import LoginController from "../backend/LoginController";
import { useDispatch } from "react-redux";
import { SET_USER } from "../store/actions";
import { toast } from 'react-toastify';

interface Props {
    ComponentToValidate: any;
}

export const Authorizor: React.FC<Props> = (props) => {
    const [waiting, setWaiting] = useState(true);
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        const validateUser = async () => {
            try {
                const user = await LoginController.verify();
                if(user){
                    dispatch({type: SET_USER, user});
                    setValidated(true);
                } else {
                    setValidated(false);
                }
            } catch (error) {
                toast.warn(error.message)
                setValidated(false);
            }
            setWaiting(false);
        };

        validateUser(); // eslint-disable-next-line
    }, []);

    if (waiting) {
        return <div>Waiting</div>;
    } else if (validated) {
        const { ComponentToValidate } = props;

        return <ComponentToValidate {...props} />;
    } else {
        return <Redirect push to="/" />;
    }
};
