import { State } from "./state";
import {
    SET_USER,
    SET_MACHINES,
    SET_SOFTWARES,
    SET_OPTIONALS,
    SET_MODULES,
    SET_MACHINE,
    SET_ENTITY,
    SET_POIS,
    SET_BENCHES,
    SET_WORKINGS,
    SET_REFERENCES,
    SET_SERVICES,
    SET_ENTITY_SECTIONS,
    SET_LOAD_SYSTEMS,
    SET_POI_NAVIGATION
} from "./actions";

const initialState: State = {
    user: null,
    machines: null,
    machine: null,
    entity: null,
    softwares: null,
    optionals: null,
    benches: null,
    loadSystems: null,
    modules: null,
    workings: null,
    references: null,
    pois: null,
    services: null,
    entitySections: [],
    poiNavigation: false
};

export const reduxStore = (state: any, action: any): State => {
    switch (action.type) {
    case SET_USER:
        return { ...state, user: action.user };
    case SET_MACHINES:
        return { ...state, machines: action.machines };
    case SET_MACHINE:
        return { ...state, machine: action.machine };
    case SET_ENTITY:
        return { ...state, entity: action.entity };
    case SET_SOFTWARES:
        return { ...state, softwares: action.softwares };
    case SET_OPTIONALS:
        return { ...state, optionals: action.optionals };
    case SET_BENCHES:
        return { ...state, benches: action.benches };
    case SET_MODULES:
        return { ...state, modules: action.modules };
    case SET_WORKINGS:
        return { ...state, workings: action.workings };
    case SET_REFERENCES:
        return { ...state, references: action.references };
    case SET_POIS:
        return { ...state, pois: action.pois };
    case SET_SERVICES:
        return { ...state, services: action.services };
    case SET_ENTITY_SECTIONS:
        return { ...state, entitySections: action.entitySections };
    case SET_LOAD_SYSTEMS:
        return { ...state, loadSystems: action.loadSystems };
    case SET_POI_NAVIGATION:
        return { ...state, poiNavigation: action.poiNavigation };
    default:
        return initialState;
    }
};
